import React from "react";
import { Modal } from "react-bootstrap";

export const DisapproveInstituteModal = (props) => {
  const handleDisapprove = (e) => {
    e.preventDefault();
    let reason = e.target.disapproveReason.value;
    props.instituteApproval(false, reason, props.institute);
    props.handleClose();
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form onSubmit={handleDisapprove} className="">
              <div className="form-group">
                <label forhtml="disapproveReason">
                  <small>سبب الرفض</small>
                </label>
                <input
                  type="text"
                  id="disapproveReason"
                  name="disapproveReason"
                  className="form-control"
                  placeholder="أكتب سبب الرفض ..."
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary">
                موافق
              </button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
