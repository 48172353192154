import { AnyAction } from "redux";
import { GlobalServiceAdapter } from "../../helpers/services";
/*********************************************/
/*********************************************/
/****************ACTION TYPES*****************/
/*********************************************/
/*********************************************/
const GET_ALL_COURSES = "GET_ALL_COURSES";
const GET_ALL_COURSES_FAILED = "GET_ALL_COURSES_FAILED";

const GET_ALL_INSTITUTES = "GET_ALL_INSTITUTES";
const GET_ALL_INSTITUTES_FAILED = "GET_ALL_INSTITUTES_FAILED";

const GET_ALL_STUDENTS = "GET_ALL_STUDENTS";
const GET_ALL_STUDENTS_FAILED = "GET_ALL_STUDENTS_FAILED";

const GET_STATISTICS = "GET_STATISTICS";
const GET_STATISTICS_FAILED = "GET_STATISTICS_FAILED";

const CLEAR_HOME = "CLEAR_HOME";

const ALL_DONE = "ALL_DONE";

/*********************************************/
/*********************************************/
/*************ACTION DISPATCHERS**************/
/*********************************************/
/*********************************************/

export const getAllCourses = () => async (dispatch: any) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/allCourses"
    );
    dispatch({ type: GET_ALL_COURSES, payload: data });
  } catch (err) {
    dispatch({ type: GET_ALL_COURSES_FAILED, payload: err.data });
  }
};
export const getAllStudents = () => async (dispatch: any) => {
  try {
    const data = await GlobalServiceAdapter.fetchData("admin/reports/students");
    dispatch({ type: GET_ALL_STUDENTS, payload: data });
  } catch (err) {
    dispatch({ type: GET_ALL_STUDENTS_FAILED, payload: err.data });
  }
};

export const getAllInstitutes = () => async (dispatch: any) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/institutes"
    );
    dispatch({ type: GET_ALL_INSTITUTES, payload: data });
  } catch (err) {
    dispatch({ type: GET_ALL_INSTITUTES_FAILED, payload: err.data });
  }
};

// ALL Statistics
export const getStatistics = () => async (dispatch: any) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/users-statistics"
    );
    dispatch({ type: GET_STATISTICS, payload: data });
  } catch (err) {
    dispatch({ type: GET_STATISTICS_FAILED, payload: err.data });
  }
};

export const clearHome = () => async (dispatch: any) => {
  dispatch({ type: CLEAR_HOME });
};

/*********************************************/
/*********************************************/
/***************REDUCER ENTITY****************/
/*********************************************/
/*********************************************/

const initialize = {
  errors: null,
  allCourses: null,
  allInstitutes: null,
  allStudents: null,
  statistics: null,
  allDone: false,
};

export function AllHome(state = initialize, { type, payload }: AnyAction) {
  switch (type) {
    case GET_ALL_INSTITUTES: {
      // console.log(payload);

      return { ...state, errors: null, allInstitutes: payload, allDone: false };
    }
    case GET_ALL_INSTITUTES_FAILED: {
      return { ...state, errors: payload, allDone: false };
    }
    case GET_ALL_STUDENTS: {
      return { ...state, errors: null, allStudents: payload, allDone: false };
    }
    case GET_ALL_STUDENTS_FAILED: {
      return { ...state, errors: payload, allDone: false };
    }
    case GET_ALL_COURSES: {
      return { ...state, errors: null, allCourses: payload, allDone: false };
    }
    case GET_ALL_COURSES_FAILED: {
      return { ...state, errors: payload, allDone: false };
    }
    case CLEAR_HOME: {
      return {
        errors: null,
        allCourses: null,
        allInstitutes: null,
        allStudents: null,
        allDone: false,
      };
    }
    case GET_STATISTICS: {
      return { errors: null, statistics: payload };
    }
    case ALL_DONE: {
      return { ...state, allDone: true };
    }
    default: {
      return { ...state };
    }
  }
}
