import Loader from "../assets/logos/min-logo.png";

export const Loading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "80vh" }}
    >
      <div className="container d-flex justify-content-center  aling-items-center">
        <img className="loader-logo" src={Loader} width="80px" alt="" />
      </div>
    </div>
  );
};
