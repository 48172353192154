import React, { useEffect, useState } from "react";
// import { insCourse } from "../../redux/actions/CoursesAction";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
// import {
//   getInstitutes,
//   instituteApproval,
// } from "../../redux/reducers";
import {
  getInstitutes,
  instituteApproval,
} from "../../redux/reducers/approval.reducer";
// import { DashboardLoading as Loading } from "../common/loading";
import { ApprovalTable } from "./approve-institute-table";
import "./instructor.css";
import { DashboardLoading as Loading } from "../common/loading";

const Statistics = (props: any) => {
  const [institute, setInstitute]: any = useState([]);
  const [loading, setLoading]: any = useState(false);

  useEffect(() => {
    setInstitute(props.approval?.institutes);
    setLoading(props.approval?.loading);
  }, [props]);

  useEffect(() => {
    props.getInstitutes();
  }, []);

  return (
    <div className="">
      <Helmet>
        <title> الشركاء التعليميين - منصة كبسولة</title>
      </Helmet>
      {loading ? (
        <Loading isLoading={true} />
      ) : (
        <ApprovalTable {...props} institute={institute} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    approval: state.Approval,
  };
};

export default connect(mapStateToProps, {
  getInstitutes,
  instituteApproval,
})(Statistics);
