import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { reducers } from "./reducers";

const persistConfig = {
  key: "Session",
  storage: storage,
};
const PR = persistReducer(persistConfig, reducers);

const store = createStore(PR, applyMiddleware(thunk));
const persister = persistStore(store);

export { persister, store };
