import { combineReducers } from "redux";

import { Session } from "./session.reducer";
import { Approval } from "./approval.reducer";
import { AllHome } from "./home.reducer";
import Categories from "./categories.reducer";

import Users from "../users.duck";
import Settings from "../settings.duck";
import Courses from "../courses.duck";
import Partners from "../partners.duck";

export const reducers = combineReducers({
  Session,
  Approval,
  AllHome,
  Categories,
  Users,
  Settings,
  Courses,
  Partners,
});

export {
  login,
  getUserInfo,
  logout,
  UpdateUserInfo,
  adminEarns,
} from "./session.reducer";
export {
  getUnapprovedCourses,
  getApprovedCourses,
  clearApproval,
  courseApproval,
  instituteApproval,
  courseLectures,
  getAllwithdrawalRequests,
  withdrawalApprove,
} from "./approval.reducer";
export {
  getAllCourses,
  getAllInstitutes,
  getAllStudents,
  clearHome,
  getStatistics,
} from "./home.reducer";
