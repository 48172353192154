import React from 'react';
import 'react-notifications-component/dist/theme.css';
import { store } from 'react-notifications-component';
// import 'animate.css';

const notify = (title, msg, type = 'default') => {
  return store.addNotification({
    title: title,
    message: msg,
    type: type, // 'default', 'success', 'info', 'warning'
    insert: 'top',
    container: 'top-right', // where to position the notifications
    animationIn: ['animated', 'fadeIn'], // animate.css classes that's applied
    animationOut: ['animated', 'fadeOut'], // animate.css classes that's applied
    dismiss: {
      duration: 3000,
      onScreen: true,
    },
  });
};

export default notify;
