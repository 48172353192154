import { useState, useEffect } from "react";
// import { Loading } from "../loading";
import { connect } from "react-redux";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Table } from "../../components/shared/Table";
import { partnersActions } from "../../redux/partners.duck";
import { coursesActions } from "../../redux/courses.duck";
import { Input } from "../../components/shared/Input";
const Categories = (props: any) => {
  const [addCatWindow, toggleCatWindow] = useState(false);
  const [partnerCourseAssign, setPartnerCourseAssign] = useState(null);
  const [showInstituteCourses, setShowInstituteCourses] = useState(false);

  const { handleSubmit, register } = useForm();
  const assignCourseToPartnerForm = useForm();

  useEffect(() => {
    props.getInstitutes();
    props.getApprovedCourses();
  }, []);

  const addPartner = (data: any) => {
    // console.log(data);
    const form: any = new FormData();

    form.append("instituteName", data.instituteName);
    form.append("bio", data.bio);
    form.append("email", data.email);
    form.append("phone", data.phone);
    form.append("website", data.website);
    form.append("logo", data.logo[0]);

    props.addInstitute(form);
    // toggleCatWindow(false);
  };

  const columns = [
    {
      dataField: "instituteName",
      text: <Trans>partner-name</Trans>,
      sort: true,
    },
    {
      dataField: "email",
      text: <Trans>email</Trans>,
      sort: true,
    },
    {
      dataField: "phone",
      text: <Trans>phone</Trans>,
      sort: true,
    },
    {
      dataField: "website",
      text: <Trans>website</Trans>,
      formatter: (_, row) => {
        return (
          <div className="">
            <a
              target="_blank"
              rel="noreferrer"
              href={row?.website}
              className="underline"
            >
              {row?.website}
            </a>
          </div>
        );
      },
    },

    {
      dataField: "codes",
      text: <Trans>شفرات المجموعة</Trans>,
      formatter: (_, row) => {
        return (
          <div
            className=""
            onClick={() => {
              const numberOfCodes = prompt("قم بادخال عدد الشفرات للطلاب");

              props.generateInstituteCodes(row.id, { numberOfCodes });
            }}
          >
            <div className="bg-indigo-600 cursor-pointer py-2 inline-block px-4 text-white rounded-[10px]">
              توليد شفرات
            </div>
          </div>
        );
      },
    },
    {
      dataField: "codes",
      text: <Trans>شفرات المجموعة</Trans>,
      formatter: (_, row) => {
        return (
          <div
            className=""
            onClick={() => {
              showInstituteCourse(row.id);
            }}
          >
            <div className="bg-indigo-600 cursor-pointer py-2 inline-block px-4 text-white rounded-[10px]">
              الكورسات المضافة
            </div>
          </div>
        );
      },
    },
    {
      dataField: "settings",
      text: <Trans>settings</Trans>,
      formatter: (_, row) => {
        return (
          <div className="" onClick={() => setPartnerCourseAssign(row)}>
            <div className="bg-indigo-600 cursor-pointer py-2 inline-block px-4 text-white rounded-[10px]">
              إضافة كورس
            </div>
          </div>
        );
      },
    },
  ];

  // console.log(props);

  const onAssignCourse = (data) => {
    // console.log(partnerCourseAssign);
    props.assignCourseToInstitute(partnerCourseAssign.id, data);
  };

  const showInstituteCourse = (id) => {
    setShowInstituteCourses(true);
    props.getInstituteCourses(id);
  };

  return (
    <div>
      <div className="">
        <div className="">
          <div className="d-flex  justify-content-between">
            <div>
              <h3>الشركاء التعليميين</h3>
            </div>
            <button
              onClick={() => toggleCatWindow(true)}
              type="submit"
              className="btn btn-primary"
            >
              إضافة شريك تعليمي
            </button>
          </div>
          <br />
        </div>

        <Modal
          centered
          size="lg"
          onHide={() => setPartnerCourseAssign(null)}
          show={partnerCourseAssign ? true : false}
        >
          <Modal.Header>
            <h3>
              إضافة كورس للشريك تعليمي {partnerCourseAssign?.instituteName}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              {props?.courses?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className=" my-1 flex items-center justify-between"
                  >
                    <div>{item.title}</div>
                    <form
                      onSubmit={assignCourseToPartnerForm.handleSubmit(
                        onAssignCourse
                      )}
                      className="flex "
                    >
                      <Input
                        className="bg-gray-100 mx-2   px-2"
                        placeholder="صلاحية الكورس"
                        type="number"
                        required={true}
                        onChanged={(data) => {
                          assignCourseToPartnerForm.setValue(
                            "enrollmentDuration",
                            data
                          );
                          assignCourseToPartnerForm.setValue(
                            "courseId",
                            item.id
                          );
                        }}
                      />
                      <button
                        onClick={() => {}}
                        className="bg-indigo-600 cursor-pointer py-2 inline-block px-4 text-white rounded-[10px]"
                      >
                        تعيين كورس
                      </button>
                    </form>
                  </div>
                );
              })}
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          onHide={() => toggleCatWindow(false)}
          show={addCatWindow}
        >
          <Modal.Header>
            <h3>إضافة شريك تعليمي</h3>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(addPartner)}>
              <div className="row text-right">
                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor=""> اسم الشريك التعليمي</label>
                  </div>
                  <input
                    {...register("instituteName", { required: true })}
                    type="text"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor=""> رقم الهاتف</label>
                  </div>
                  <input
                    {...register("phone", { required: true })}
                    type="text"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor="">البريد الإلكتروني</label>
                  </div>
                  <input
                    {...register("email", { required: true })}
                    type="text"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor="">صفحة الويب</label>
                  </div>
                  <input
                    {...register("website", { required: true })}
                    type="text"
                    className="col-12"
                  />
                </div>

                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor="">صورة هوية الشريك </label>
                  </div>
                  <input
                    {...register("logo", { required: true })}
                    type="file"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor="">نبذة عن الشريك</label>
                  </div>
                  <textarea
                    {...register("bio", { required: true })}
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12 my-4">
                  <button className="btn btn-primary col-12 text-center">
                    حفظ
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="">
        <div className="">
          <div className="">
            {props.partners?.length < 1 ? (
              <div className="text-center">
                <Trans>no-content</Trans>
              </div>
            ) : (
              <Table
                withSearch={false}
                data={props.partners || []}
                columns={columns}
              >
                <button
                  onClick={() => toggleCatWindow(true)}
                  type="submit"
                  className="btn btn-primary"
                >
                  إضافة شريك تعليمي
                </button>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.Settings.loading,
    courses: state.Courses.courses,
    partners: state.Partners.partners,
  };
};

export default connect(mapStateToProps, {
  ...partnersActions,
  ...coursesActions,
})(Categories);
