// import { AnyAaaaRecord } from "dns";
import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import { DisapproveModal } from "../common/reasonModal";
import { PercentModal } from "../common/percentModal";
import { ModalPlayer } from "../common/modal";

export const ApprovalTable = (props: {
  courses: Array<any>;
  courseApproval: any;
}) => {
  const [show, setShow] = useState(false);
  const [showPercent, setShowPercent] = useState(false);
  const [showCapsula, setShowCapsula] = useState(false);
  const [oneCourse, setOneCourse]: any = useState({});
  const [courseID, setCourseID] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShowPercent = () => setShowPercent(true);
  const handleClosePercent = () => setShowPercent(false);

  return (
    <div>
      <div className="course-tables">
        <div className="float-right">
          <h3>الكورسات - طلبات الموافقة </h3>
          <br></br>
        </div>
        <Table className="the-table" responsive bordered hover>
          <thead>
            <tr>
              <th>اسم الكورس</th>
              {/* <th>سعر الكورس</th>
              <th>اسم الاستاذ</th>
              <th>وصف الكورس</th> */}
              <th>تاريخ الإنشاء</th>
              <th>
                <i className="far fa-videp" /> كبسولة
              </th>
              <th>
                <i className="far fa-video" /> المحاضرات
              </th>
              <th>
                <i className="far fa-gear" /> اعدادات
              </th>
            </tr>
          </thead>
          {props.courses &&
            props.courses.map((course, idx) => {
              return (
                <tbody key={idx}>
                  <tr>
                    <td className="align-middle" title={course.title}>
                      <a
                        target="_blank"
                        href={`https://kpsolla.com/visit?id=${course.id}`}
                      >
                        {course.title}
                      </a>
                    </td>

                    <td className="align-middle">{course.price} SDG</td>
                    {/* <td className="align-middle">{course.author?.firstName}</td> */}
                    {/* <td className="align-middle" title={course.descriptions}>
                      {course.descriptions.slice(0, 18) + "..."}
                    </td>
                    <td className="align-middle">
                      {new Date(course.createdAt).toLocaleDateString()}
                    </td> */}
                    <td className="align-middle">
                      {course.capsula ? (
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setShowCapsula(true);
                            setOneCourse(course);
                          }}
                        >
                          مشاهدة
                        </Button>
                      ) : (
                        <span>لا يوجد كبسولة</span>
                      )}
                    </td>

                    <td>
                      <div className="m-1">
                        <Button
                          variant="secondary"
                          onClick={() =>
                            (window.location.href =
                              "/courses/lectures/" + course.id)
                          }
                        >
                          المحاضرات
                        </Button>
                      </div>
                    </td>
                    <td style={{ position: "relative" }}>
                      <div className="d-flex text-center">
                        <div className="m-1">
                          <Button
                            variant="success"
                            onClick={() => {
                              handleShowPercent();
                              setCourseID(course.id);
                              oneCourse(course);
                            }}
                          >
                            موافقة
                          </Button>
                        </div>

                        <div className="m-1">
                          <Button
                            variant="danger"
                            onClick={() => {
                              handleShow();
                              setCourseID(course.id);
                            }}
                          >
                            رفض
                          </Button>
                          <DisapproveModal
                            {...props}
                            course={courseID}
                            show={show}
                            handleClose={handleClose}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          <PercentModal
            percent={oneCourse.companyPercent}
            {...props}
            course={courseID}
            show={showPercent}
            handleClose={handleClosePercent}
          />
          <ModalPlayer
            course={oneCourse}
            show={showCapsula}
            handleClose={() => setShowCapsula(false)}
          />
        </Table>
      </div>
    </div>
  );
};
