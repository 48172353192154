import {
  GlobalServiceAdapterV2,
  GlobalServiceAdapter,
} from "../helpers/services";
import FileDownload from "js-file-download";

const GET_REGISTERED_USERS = "USERS/GET_REGISTERED_USERS";
const LOADING = "USERS/LOADING";
const STOP_LOADING = "USERS/STOP_LOADING";
const GET_CERTIFICATE_REQUESTS = "USERS/GET_CERTIFICATE_REQUESTS";

export default function (state, { type, payload }) {
  switch (type) {
    case GET_REGISTERED_USERS: {
      return {
        ...state,
        loading: false,
        users: payload,
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CERTIFICATE_REQUESTS:
      return {
        ...state,
        loading: false,
        requests: payload,
      };
    case STOP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    default: {
      return {
        ...state,
        loading: false,
      };
    }
  }
}

const getRegisteredUsers = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/web/registered-users"
    );

    dispatch({ type: GET_REGISTERED_USERS, payload: data });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};
const getCertificateRequests = (status?: number) => async (dispatch) => {
  // dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapterV2.fetchData(
      "courses/certificate-requests?status=" + status
    );

    dispatch({ type: GET_CERTIFICATE_REQUESTS, payload: data });
    // console.log(data);
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};
const updateCertificateRequests = (status: number) => async (dispatch) => {
  // dispatch({ type: LOADING });
  try {
    await GlobalServiceAdapterV2.updateData(
      "courses/certificate-requests/change-status",
      status
    );
    getCertificateRequests(status)(dispatch);
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};

const downloadRequests = (query) => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapterV2.downloadFile(
      "courses/certificate-requests/csv?status=" + query.status
    );
    FileDownload(data, `${query.title}.csv`);
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};

export const usersActions = {
  getRegisteredUsers,
  getCertificateRequests,
  updateCertificateRequests,
  downloadRequests,
};
