import { storage } from "./storage.adapter";
class SystemStatus {
  getAuthStatus() {
    try {
      return JSON.parse(storage.useLocalStorage().getItem("authenticated"));
    } catch (err) {
      return false;
    }
  }

  getSuspendStatus() {
    try {
      return JSON.parse(storage.useCookie().getItem("suspend"));
    } catch (err) {
      return false;
    }
  }

  saveAuthData(token: string) {
    storage.useLocalStorage().setItem("authenticated", true);
    storage.useLocalStorage().setItem("accessToken", token);

    window.location.replace("/home");
  }
  getUserInfo() {
    try {
      return JSON.parse(storage.useLocalStorage().getItem("user"));
    } catch (err) {
      return false;
    }
  }
  discardAuthData(refresh?: boolean) {
    storage.useLocalStorage().setItem("accessToken", null);
    storage.useLocalStorage().setItem("authenticated", false);

    if (refresh) {
      window.location.reload();
    }
  }

  getToken() {
    return storage.useLocalStorage().getItem("accessToken");
  }

  suspendSystem() {
    storage.useCookie().setItem("suspend", true);
    window.location.replace("/lockscreen");
  }
  desuspendSystem() {
    storage.useCookie().setItem("suspend", false);
    window.location.replace("/login");
  }

  getSystemLang(lang: "en" | "ar") {
    return storage.useLocalStorage().getItem("lang") === lang;
  }

  changeSystemLang(lang: string) {
    storage.useLocalStorage().setItem("lang", lang);
    // localStorage.setItem("lang", lang);

    window.location.reload();
  }
}
export const systemStatus = new SystemStatus();
