import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { Loading } from "../loading";
import { connect } from "react-redux";
import { categoriesActions } from "../../redux/reducers/categories.reducer";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
const Categories = (props: any) => {
  const [addCatWindow, toggleCatWindow] = useState(false);
  const [item, setItem]: any = useState(null);
  const [categories, setCategories]: any = useState(null);

  const { handleSubmit, setValue, register } = useForm();
  useEffect(() => {
    props.getCategories();
  }, []);

  useEffect(() => {
    setCategories(props.categories);
  }, [props.categories]);

  const addCategory = (data: any) => {
    if (item) {
      // alert("update");
      data.parent = item?.id;
    } else {
      delete data.parent;
    }

    const form = new FormData();
    form.append("en_name", data.categoryName);
    form.append("ar_name", data.ar_name);
    form.append("categoryName", data.categoryName);
    form.append("description", data.description);
    form.append("image", data.image[0]);
    props.addCategory(form);
    toggleCatWindow(false);
  };

  return (
    <div>
      {!props.loading ? (
        <div className="">
          <div className="">
            <div className="d-flex   justify-content-between">
              <div>{/* <h3>اصناف الكورسات </h3> */}</div>

              <button
                onClick={() => toggleCatWindow(true)}
                type="submit"
                className="btn btn-primary"
              >
                إضافة صنف
              </button>
            </div>
            <br />
          </div>

          <Modal onHide={() => toggleCatWindow(false)} show={addCatWindow}>
            <Modal.Header>
              {item ? <h3>تعديل الصنف </h3> : <h3>إضافة صنف جديد</h3>}
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit(addCategory)}>
                <div className="row text-right">
                  <div className="input-container col-12">
                    <div className="mt-2">
                      <label htmlFor="">الاسم بالانجليوي</label>
                    </div>
                    <input
                      {...register("categoryName", { required: true })}
                      type="text"
                      className="col-12"
                    />
                  </div>
                  <div className="input-container col-12">
                    <div className="mt-2">
                      <label htmlFor="">الاسم بالعربي</label>
                    </div>
                    <input
                      {...register("ar_name", { required: true })}
                      type="text"
                      className="col-12"
                    />
                  </div>
                  <div className="input-container col-12">
                    <div className="mt-2">
                      <label>صورة الصنف</label>
                    </div>
                    <input
                      {...register("image", { required: true })}
                      type="file"
                      className="col-12"
                    />
                  </div>
                  <div className="input-container col-12">
                    <div className="mt-2">
                      <label htmlFor="">الوصف</label>
                    </div>

                    <textarea
                      {...register("description", { required: true })}
                      rows={4}
                      className="col-12"
                    />
                  </div>
                  <div className="input-container col-12">
                    <button className="btn btn-primary">حفظ</button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
          <Table className="the-table" responsive bordered hover>
            <thead>
              <tr>
                <th scope="col">اسم الصنف</th>
                <th scope="col">الوصف</th>
                <th scope="col">الاصناف الفرعية</th>
                <th scope="col">تاريخ الإضافة</th>
                <th scope="col"> خيارات</th>
              </tr>
            </thead>
            <tbody>
              {categories?.map((item: any, idx: any) => {
                return (
                  <tr key={idx}>
                    <td>{item.categoryName}</td>
                    <td>{item.description}</td>
                    <td>
                      {item?.subCategory?.[0] ? (
                        <div>
                          <div>
                            <button
                              onClick={() => setCategories(item?.subCategory)}
                              className="btn btn-primary"
                            >
                              عرض الاصناف الفرعية
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>لا يوجد اصناف فرعية</div>
                      )}
                    </td>
                    <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                    <td>
                      <div>
                        <button
                          onClick={() => {
                            setItem(item);
                            toggleCatWindow(true);
                          }}
                          className="btn btn-primary"
                        >
                          إضافة صنف فرعي
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  // console.log(state.Categories.categories);

  return {
    categories: state.Categories.categories,
    loading: state.Categories.loading,
    // approve: state.Approval.approveWithdrawalRequest,
  };
};

export default connect(mapStateToProps, {
  ...categoriesActions,
})(Categories);
