import i18next from "i18next";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Trans } from "react-i18next";
import { systemStatus } from "../../helpers/auth.status";
const { SearchBar } = Search;

interface Props {
  data: any;
  columns: any;
  withSearch?: boolean;
  children?: any;
  onFilter?: any;
  useFilter?: boolean;
  title?: any;
  orderBy?: String
}

export const Table = (props: Props) => {
  const defaultSorted = [
    {
      dataField: props.orderBy ?? "id",
      order: "desc",
    },
  ];
  return (
    <div className="">
      {props.title ? <div className="pl-3 pr-3 my-3">{props.title}</div> : ""}
      <div className="col-12 p-0">
        <ToolkitProvider
          keyField="id"
          bootstrap4
          data={props.data || []}
          columns={props.columns}
          search
        >
          {(tProps: any) => (
            <div>
              {props.withSearch && 
              (
                <div dir="rtl" className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                    <SearchBar
                      placeholder={i18next.t("search")}
                      {...tProps.searchProps}
                    />
                    {props.useFilter && (
                      <div
                        onClick={props?.onFilter}
                        className=" badge-primary ml-2 mr-2"
                        style={{
                          height: 46,
                          padding: 15,
                          borderRadius: 4,
                          cursor: "pointer",
                        }}
                      >
                        <i className="mdi mdi-filter-outline" />
                      </div>
                    )}
                  </div>
                  <div>{props.children}</div>
                </div>
              )}

              <BootstrapTable
                defaultSorted={defaultSorted}
                pagination={paginationFactory({
                  sizePerPage: 10,
                })}
                {...tProps.baseProps}
                wrapperClasses="table-responsive"
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
};
