import React, { useEffect, useState } from 'react';
import { Modal, Row ,Col, Button, Container } from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalBody from 'react-bootstrap/ModalBody';

export const IPN = (props) => {

    const [withdrawal, setWithdrawal] = useState(false);
    const [requestID, setRequestID] = useState(null);

    useEffect(() => {
        if(props?.show){
          setWithdrawal(true);
        }else{
          setWithdrawal(false);
        }
        if(props?.request){
            setRequestID(props?.request?.id);
        }
      }, [props]);

      const formSubmit = (e)=> {

        e.preventDefault();

        let data = new FormData(e.target);


        let ipin = data.get("ipin");
        // console.log(ipin);

        props.approve({ipin:ipin,id:requestID});
        props.handleClose()
      }

    return (
        <>
            <Modal show={withdrawal} onHide={props?.handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <ModalHeader>{"تأكيد الموافقة"}</ModalHeader>
                <ModalBody>
                    <Container className="bg-light p-3 mb-4">
                        <div><strong>{"ملاحظة !!"}</strong></div>
                        <br/>
                        <div>{"الرجاء كتابة الرقم السري الخاص ببطاقتك"}</div>
                    </Container>
                    <form onSubmit={formSubmit}>
                        <Container>
                            <div className="d-flex flex-row justify-content-around">
                                <input type="password" name="ipin" maxLength={4} placeholder="####" className="col-12 mx-1 form-control form-control-lg text-center" required={true}/>
                            </div>
                                <br />
                                <hr/>
                            <div className="d-flex flex-row justify-content-around">
                                <Button
                                    className="col-4"
                                    // onClick={()=> setWithdrawal(false)}
                                    variant="primary"
                                    type="submit">
                                    {'موافقة'}
                                </Button>

                                <Button
                                    className="col-4"
                                    variant="secondary"
                                    onClick={()=> setWithdrawal(false)}>
                                    {'خروج'}
                                </Button>
                            </div>
                    </Container>
              </form>
            </ModalBody>
        </Modal>
        </>
    );

}