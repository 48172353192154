export function extractQuery(query) {
  const newQuery = query.substring(1);
  const j = {};
  newQuery.split("&").filter(function (val) {
    j[val.split("=")[0]] = val.split("=")[1];

    return j;
  });
  return j;
}
