import { AnyAction } from "redux";
import notify from "../../components/common/notification-system/notification";
import { GlobalServiceAdapter } from "../../helpers/services";
import { systemStatus } from "../../helpers/auth.status";

/*********************************************/
/*********************************************/
/****************ACTION TYPES*****************/
/*********************************************/
/*********************************************/
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOADING = "LOADING";
const LOGIN_FAILED = "LOGIN_FAILED";
const GET_USER_INFO = "GET_USER_INFO";
const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILED = "UPDATE_USER_INFO_FAILED";

export const UPDATE_USER_PICTURE_SUCCESS = "UPDATE_USER_PICTURE_SUCCESS";
export const UPDATE_USER_PICTURE_FAILED = "UPDATE_USER_PICTURE_FAILED";

export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAILED = "UPDATE_USER_PASSWORD_FAILED";

export const GET_ADMIN_EARNS_SUCCESS = "GET_ADMIN_EARNS_SUCCESS";
export const GET_ADMIN_EARNS_ERROR = "GET_ADMIN_EARNS_ERROR";

/*********************************************/
/*********************************************/
/*************ACTION DISPATCHERS**************/
/*********************************************/
/*********************************************/

// login redux action, this action will perform login api request uses api proxies
export const login = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // send api request through proxy and store response
    const payload = await GlobalServiceAdapter.sendData("auth/login", data);
    // localStorage.setItem("user", JSON.stringify(payload.user));

    systemStatus.saveAuthData(payload?.token);

    getUserInfo()(dispatch);
    // dispatch({ type: Types.LOGIN_SUCCESS, payload: payload?.user });
  } catch (err) {
    console.log(err);

    // if request is failed dispatch error
    dispatch({ type: LOGIN_FAILED, payload: err.message });
  }
};
export const getUserInfo = () => async (dispatch: any) => {
  dispatch({ type: LOADING });

  try {
    const data = await GlobalServiceAdapter.fetchData("users/userInfo");
    // const { level } = data?.roles.find((role: any) => role.level === 0);
    // cookies.set("role", level, { secure: false }); // set admin role
    delete data.roles;
    dispatch({ type: GET_USER_INFO, payload: data });
  } catch (err) {
    dispatch({ type: GET_USER_INFO_FAILED, payload: err.data });
  }
};

export const logout = () => async (dispatch: any) => {
  systemStatus.discardAuthData(true);
};
// Updating user PASSWORD
export const UpdateUserPassword = (info: any) => async (dispatch: any) => {
  dispatch({ type: LOADING });

  try {
    // ...
    const data = await GlobalServiceAdapter.updateData(
      "users/Reset-Password",
      info
    );
    // console.log(data);
    dispatch({ type: UPDATE_USER_PASSWORD_SUCCESS, payload: data });
    notify("نجاح", "تم تغيير كلمة السر بنجاح", "success");
  } catch (err) {
    // ...
    dispatch({ type: UPDATE_USER_PASSWORD_FAILED, payload: err.data });

    notify("خطأ", "خطأ في كلمة السر القديمة", "danger");
  }
};

export const UpdateUserInfo = (info: any) => async (dispatch: any) => {
  dispatch({ type: LOADING });

  try {
    const data = await GlobalServiceAdapter.updateData("users/update", info);

    dispatch({ type: UPDATE_USER_INFO_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: UPDATE_USER_INFO_FAILED, payload: err.data });
  }
};

export const UpdateUserImage = (image: any) => async (dispatch: any) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.updateFiles(
      "users/profileImage",
      image
    );
    // console.log(data);

    dispatch({ type: UPDATE_USER_INFO_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: UPDATE_USER_INFO_FAILED, payload: err.data });
  }
};

export const adminEarns = () => async (dispatch: any) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData(`payments/admin-earns`);

    dispatch({
      type: GET_ADMIN_EARNS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({ type: GET_ADMIN_EARNS_ERROR, payload: err.data });
  }
};

export const sessionActions = {
  getUserInfo,
  UpdateUserImage,
  login,
  UpdateUserInfo,
};

/*********************************************/
/*********************************************/
/***************REDUCER ENTITY****************/
/*********************************************/
/*********************************************/

const initialize = {
  updateUserInfo: null,
  updateUserImage: null,
  updatePassword: null,
  adminEarn: null,
  loading: false,
  lang: "en",
  errors: null,
  userInfo: null,
};

export function Session(state = initialize, { type, payload }: AnyAction) {
  switch (type) {
    case LOADING: {
      return { ...state, loading: true };
    }
    case LOGIN_SUCCESS: {
      return { ...state, errors: null, loading: false };
    }
    case LOGIN_FAILED: {
      return { ...state, errors: payload, loading: false };
    }
    case GET_USER_INFO: {
      return { ...state, userInfo: payload, loading: false };
    }
    case GET_USER_INFO_FAILED: {
      return { ...state, errors: payload, loading: false };
    }

    // updating information
    case UPDATE_USER_INFO_SUCCESS: {
      console.log(payload);

      return { ...state, errors: null, userInfo: payload, loading: false };
    }
    case UPDATE_USER_INFO_FAILED: {
      return { ...state, errors: payload, userInfo: null, loading: false };
    }

    // updating profile image
    case UPDATE_USER_PICTURE_SUCCESS: {
      return {
        ...state,
        errors: null,
        updateUserImage: payload,
        loading: false,
      };
    }
    case UPDATE_USER_PICTURE_FAILED: {
      return {
        ...state,
        errors: payload,
        updateUserImage: null,
        loading: false,
      };
    }

    // admin earn image
    case GET_ADMIN_EARNS_SUCCESS: {
      return { ...state, errors: null, adminEarn: payload, loading: false };
    }
    case GET_ADMIN_EARNS_ERROR: {
      return { ...state, errors: payload, adminEarn: null, loading: false };
    }

    case UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        updatePassword: payload,
        errors: null,
        loading: false,
      };
    }
    case UPDATE_USER_PASSWORD_FAILED: {
      return {
        ...state,
        updatePassword: null,
        errors: payload,
        loading: false,
      };
    }

    default: {
      return { ...state };
    }
  }
}
