import React, { useEffect } from "react";
import Sidebar from "./sidebar";
import SettingsSidebar from "./settingsSidebar";
import UpperNav from "./navbar";
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";

const Layout = ({ children }: any) => {
  const url = useLocation().pathname.substring(1);

  useEffect(() => {
    document.title = "منصة كبسولة - لوحة التحكم ";
  }, []);

  return (
    <div>
      <div className="flex bg-gray-50">
        <Sidebar />
        <div className="grow sm:px-4 grow h-screen overflow-y-scroll">
          <UpperNav />
          <div className="px-4">
            <div className=" px-2 text-base text-gray-500">
              <p>
                لوحة التحكم | <Trans>{url}</Trans>
              </p>
            </div>
          </div>
          <div className="px-4">
            <div className=" px-2 ">{children}</div>
          </div>
        </div>
        <SettingsSidebar />
      </div>
    </div>
  );
};
export default Layout;
