import React, { useEffect, useState } from "react";
import { Modal } from 'react-bootstrap';

export const PercentModal = (props) => {
  const [spinner, setSpinner] = useState("موافق");
  const [percent, setPercent] = useState("");

    const handleApproveNPercent = (e) => {
      e.preventDefault();

       let companyPercent =  Number(e.target.companyPercent.value);
       setSpinner(<span className="fa fa-spinner fa-spin"></span>);
      //  document.querySelector('button[type="submit"]').innerText = ;

       if(!companyPercent){

         props.courseApproval(true,
          'course approved successfuly !',
          props.course,
          20);

        }else{
          
          props.courseApproval(true,
            'course approved successfuly !',
            props.course,
            companyPercent);
            
       }
       

    }
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title></Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <form onSubmit={handleApproveNPercent} className="">
                <div className="alert alert-secondary"> إنتبه إلى <strong>النسبة</strong> !! <br/>
                    <ul>
                        <li>إذا كانت <strong>النسبة</strong> مضافة مسبقا لايمكن تعديلها مرة أخرى</li>
                        {/* <li>If you didn't add  <strong>percent</strong> the System automatically added for you with <strong>20%</strong> </li> */}
                    </ul>
                </div>
                <div className="form-group">
                    <label forhtml="companyPercent"><small>نسبة الشركة</small></label>
                    <input type="number" id="companyPercent" min="0" max="100" name="companyPercent" className="form-control" placeholder="نسبة الشركة" onChange={(e)=> setPercent(e.target.value)} value={percent} required={true}/>
                </div>
                <button type="submit" className="btn btn-primary">{spinner}</button>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}
