import { GlobalServiceAdapter } from "../helpers/services";
import FileDownload from "js-file-download";

const LOADING = "USERS/LOADING";
const STOP_LOADING = "USERS/STOP_LOADING";
const GET_EDUCATION_PARTNERS = "USERS/GET_EDUCATION_PARTNERS";

export default function PartnersReducer(state, { type, payload }) {
  switch (type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_EDUCATION_PARTNERS:
      return {
        ...state,
        loading: false,
        partners: payload,
      };
    case STOP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    default: {
      return {
        ...state,
        loading: false,
      };
    }
  }
}

const addInstitute = (info: any) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.sendFiles("institutes", info);

    console.log(data);
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};
const getInstitutes = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData("institutes");

    // console.log(data);

    dispatch({ type: GET_EDUCATION_PARTNERS, payload: data });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};

const assignCourseToInstitute = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await GlobalServiceAdapter.sendData(
      `institutes/addCourseAccessToInstitute/${id}`,
      data
    );

    dispatch({ type: STOP_LOADING });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
  }
};

const generateInstituteCodes = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const file = await GlobalServiceAdapter.downloadFile(
      `institutes/generateInstituteCodes/${id}?numberOfCodes=${data.numberOfCodes}`
    );

    FileDownload(file, `${new Date().getTime()}.csv`);

    // dispatch({ type: STOP_LOADING });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
  }
};

const getInstituteCourses = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `institutes/getInstituteAllowedCourses/${id}`
    );

    console.log(data);

    // dispatch({ type: GET_EDUCATION_PARTNERS, payload: data });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};

export const partnersActions = {
  addInstitute,
  getInstitutes,
  assignCourseToInstitute,
  generateInstituteCodes,
  getInstituteCourses,
};
