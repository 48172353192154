import notify from "../components/common/notification-system/notification";
import {
  GlobalServiceAdapter,
  GlobalServiceAdapterV2,
} from "../helpers/services";

const GET_COURSES_PROMOSIONS = "COURSES/GET_COURSES_PROMOSIONS";
const GET_APPROVED_COURSES = "COURSES/GET_APPROVED_COURSES";
const LOADING = "COURSES/LOADING";
const STOP_LOADING = "COURSES/STOP_LOADING";
const SET_UPLOAD_PERCENTAGE = "COURSES/SET_UPLOAD_PERCENTAGE";
const UPDATE_LECTURE_ERROR = "COURSES/UPDATE_LECTURE_ERROR";

export default function CourseReducer(state, { type, payload }) {
  switch (type) {
    case GET_COURSES_PROMOSIONS:
      return {
        ...state,
        loading: false,
        promotions: payload,
      };
    case SET_UPLOAD_PERCENTAGE:
      return {
        ...state,
        progressPercentage: payload,
      };

    case GET_APPROVED_COURSES:
      return {
        ...state,
        loading: false,
        courses: payload,
      };

    case LOADING:
      return {
        ...state,
        loading: true,
      };

    case STOP_LOADING:
      return {
        ...state,
        loading: true,
      };

    case "LECTURE_CREATED_SUCCESSFULLY":
      return {
        ...state,
        loading: false,
        lectureAdded: true,
      };

    case UPDATE_LECTURE_ERROR:
      return {
        ...state,
        lectcureAdded: false,
        loading: false,
        progressPercentage: 0,
      };
    default:
      return {
        ...state,
        loading: false,
        lectureAdded: false,
        progressPercentage: 0,
      };
  }
}

const getPromosions = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapterV2.fetchData(
      "courses/promotions/new"
    );

    dispatch({ type: GET_COURSES_PROMOSIONS, payload: data });
    // console.log(data);
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};
const createPromosions = (info) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await GlobalServiceAdapterV2.sendFiles("courses/promotions", info);

    getPromosions()(dispatch);
    // dispatch({ type: GET_COURSES_PROMOSIONS, payload: data });
    // console.log(data);
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};

const getApprovedCourses = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const { courses } = await GlobalServiceAdapter.fetchData(
      "admin/reports/approvedCourses"
    );

    dispatch({ type: GET_APPROVED_COURSES, payload: courses });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};
const updateLecture = (id, info) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // console.log(id, info);
    await GlobalServiceAdapter.updateFiles(
      "courses/lectures/update/" + id,
      info
    );

    dispatch({ type: "LECTURE_CREATED_SUCCESSFULLY" });
  } catch (err) {
    notify("error", "cannot process lecture", "warning");
    dispatch({ type: UPDATE_LECTURE_ERROR });
    //
  }
};
const updateLectureTime = (id, info) => async (dispatch) => {
  // dispatch({ type: LOADING });
  try {
    // console.log(id, info);
    await GlobalServiceAdapter.updateData(
      "courses/lectures/" + id + "/update",
      info
    );
    notify("success", "lecture time updated", "success");

    // dispatch({ type: "LECTURE_CREATED_SUCCESSFULLY" });
  } catch (err) {
    notify("error", "cannot update lecture time", "warning");
    dispatch({ type: UPDATE_LECTURE_ERROR });
    //
  }
};

const opserveUploadProgress = (percentage: number) => async (dispatch) => {
  dispatch({ type: SET_UPLOAD_PERCENTAGE, payload: percentage });
};
export const coursesActions = {
  getPromosions,
  getApprovedCourses,
  createPromosions,
  updateLecture,
  opserveUploadProgress,
  updateLectureTime,
};
