import React, { useEffect, useState } from "react";
import "./instructor.css";
import { connect } from "react-redux";
import { adminEarns } from "../../redux/reducers/session.reducer";
import {EarnsTable} from "./earnTable";
import { DashboardLoading as Loading } from "../common/loading";

const Earns = (props:any) => {
  const [earns, setEarns]:any = useState({});
  const [wait, setWait] = useState(true);

  useEffect(() => {
      console.log(props.earns);
      
    if (props.earns) {
      setEarns(props?.earns);
      setWait(false);
    }
  }, [props]);

  useEffect(() => {
    props.adminEarns();
  }, []);
  return !wait ? (
    <div className="instructor-courses">
      <EarnsTable earns={props?.earns} />
    </div>
  ) : (
    <Loading isLoading={wait} />
  );
};

function mapStateToProps(state:any) {
  return {
    earns: state.Session.adminEarn,
  };
}

export default connect(mapStateToProps, { adminEarns })(Earns);
