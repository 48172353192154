import { APIProxy } from "../../helpers/proxy";
import { AnyAction } from "redux";
import { GlobalServiceAdapter } from "../../helpers/services";
/*********************************************/
/*********************************************/
/****************ACTION TYPES*****************/
/*********************************************/
/*********************************************/
const GET_CATEGORIES = "GET_CATEGORIES";
const LOADING = "LOADING";
const ADD_CATEGORY = "ADD_CATEGORY";
const ERRORS = "ERRORS";

/*********************************************/
/*********************************************/
/*************ACTION DISPATCHERS**************/
/*********************************************/
/*********************************************/

const getCategories = () => async (dispatch: any) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/settings/categories"
    );

    dispatch({ type: GET_CATEGORIES, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS, payload: err.data });
  }
};
const addCategory = (info: any) => async (dispatch: any) => {
  dispatch({ type: LOADING });
  try {
    await GlobalServiceAdapter.sendFiles("admin/settings/categories", info);

    // console.log(data);
    getCategories()(dispatch);
    // dispatch({ type: ADD_CATEGORY, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS, payload: err.data });
  }
};
/*********************************************/
/*********************************************/
/***************REDUCER ENTITY****************/
/*********************************************/
/*********************************************/

const initialize = {
  errors: null,
  categories: null,
  loading: false,
};

export default function (state = initialize, { type, payload }: AnyAction) {
  switch (type) {
    case LOADING: {
      return { ...state, loading: true };
    }
    case GET_CATEGORIES: {
      return { ...state, errors: null, categories: payload, loading: false };
    }

    default: {
      return { ...state };
    }
  }
}

export const categoriesActions = {
  getCategories,
  addCategory,
};
