import { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";

import { LectureModalPlayer } from "../common/lectureModal";
import { DashboardLoading as Loading } from "../common/loading";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { courseLectures } from "../../redux/reducers";
import { Trans } from "react-i18next";
// import { extractQuery } from "../../util/extracter";
import { coursesActions } from "../../redux/courses.duck";
import { useForm } from "react-hook-form";

const CourseLectures = (props: any) => {
  const { courseID }: any = useParams();
  const [show, setShow]: any = useState(false);
  const [lectures, setLecures]: any = useState([]);
  const [oneLecture, setOneLecure]: any = useState([]);
  const [loading, setLoading]: any = useState(true);
  const [lectureAdded, toggleLectureAdded] = useState(false);
  const [video, setVideo] = useState(null);
  const [item, setItem] = useState(null);

  const updateLectureForm = useForm();

  useEffect(() => {
    if (props.lectures?.lectures) {
      setLecures(props.lectures?.lectures);
      setLoading(false);
      console.log(props.lectures?.lectures);
    }
    if (props?.lectureAdded && !props?.loading) {
      toggleLectureAdded(props.lectureAdded);
    }
  }, [props]);

  useEffect(() => {
    props.courseLectures(courseID);
  }, []);

  const updateLecture = (event) => {
    // console.log(event.target.files);
    if (!event.target.files?.[0]) return;

    const form = new FormData();
    form.append("video", event.target.files[0]);

    // alert(item.id);
    props.updateLecture(item.id, form);
  };

  // const onUpdate = (data) => {
  //   console.log(data);
  // };

  return (
    <div>
      <div className="course-tables">
        <div className="float-right">
          <h3>قائمة المحاضرات</h3>
          <br></br>
        </div>
        <Table className="the-table" responsive bordered hover>
          <thead>
            <tr>
              <th>رقم المحاضرة</th>
              <th>
                {" "}
                <i className="far fa-info" /> عنوان المحاضرة
              </th>
              <th>زمن المحاضرة</th>
              <th>
                {" "}
                <i className="far fa-info" /> ملاحظة
              </th>
              <th>
                <i className="far fa-video" /> تعديل زمن المحاضرة
              </th>
              <th>
                <i className="far fa-video" /> فديو المحاضرة
              </th>
              <th>
                <i className="far fa-cog" /> تعديل الفيديو
              </th>
            </tr>
          </thead>
          {!loading &&
            lectures?.lectures?.map((lecture: any) => {
              return (
                <tbody key={lecture.id}>
                  <tr>
                    <td>
                      <strong>{lecture.number}</strong>
                    </td>

                    <td>
                      <div>{lecture.title}</div>
                    </td>
                    <td>
                      <div>
                        <small>{lecture.lectureTime} min</small>
                      </div>
                    </td>
                    <td>
                      <div>
                        <small>{lecture.descriptions}</small>
                      </div>
                    </td>

                    <td>
                      <button
                        onClick={() => {
                          const time = prompt(
                            `update time for lecture "${lecture.title}"`
                          );

                          // const form = new FormData();
                          // form.append("lectureTime", time);
                          if (time) {
                            props.updateLectureTime(lecture.id, {
                              lectureTime: time,
                            });
                          }
                        }}
                        className="btn btn-primary"
                      >
                        <Trans>update-time</Trans>{" "}
                        <i className="fa fa-save"></i>
                      </button>
                    </td>
                    <td>
                      <div>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setShow(true);
                            setOneLecure(lecture);
                          }}
                        >
                          شاهد
                        </Button>
                      </div>
                    </td>
                    <td>
                      <div>
                        <input
                          type="file"
                          id="file"
                          className="form-control"
                          style={{ display: "none" }}
                          value={video}
                          accept="video/*"
                          onChange={(event) => {
                            // setVideo(event.target.files[0])
                            updateLecture(event);
                          }}
                        />
                        <label
                          className="btn btn-primary"
                          htmlFor="file"
                          onClick={() => {
                            // alert(lecture.id);
                            setItem(lecture);
                          }}
                        >
                          اختيار المفيديو
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          <LectureModalPlayer
            courseId={courseID}
            lecture={oneLecture}
            show={show}
            handleClose={() => setShow(false)}
          />
        </Table>
        {loading && <Loading isLoading={true} />}
      </div>
      {lectureAdded ? (
        <LectureSuccessMessage
          close={() => toggleLectureAdded(false)}
          showProgress={false}
          courseId={courseID}
          lectureAdded={props.lectureAdded}
          loading={props.loading}
        />
      ) : null}
      {props.progressPercentage > 0 && props.progressPercentage < 100 ? (
        <LectureSuccessMessage
          addNewLecture={() => toggleLectureAdded(false)}
          courseId={courseID}
          lectureAdded={props.lectureAdded}
          loading={props.loading}
          showProgress={true}
          progressPercentage={props.progressPercentage}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    lectures: state.Approval,
    lectureAdded: state.Courses?.lectureAdded,
    progressPercentage: state.Courses?.progressPercentage,
  };
};

export default connect(mapStateToProps, { courseLectures, ...coursesActions })(
  CourseLectures
);

const LectureSuccessMessage = (props: any) => {
  if (props.showProgress === true)
    return (
      <div
        className=" p-4"
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          background: "#e0e0e061",
          zIndex: 9,
        }}
      >
        <div
          className=" p-4 row d-flex align-items-center"
          style={{
            position: "absolute",
            width: 440,
            minHeight: 250,
            background: "white",
            top: "calc(50% - 100px)",
            left: "calc(50% - 220px)",
            zIndex: 10,
            borderRadius: 8,
            boxShadow: "0 11px 34px 0 #d8d8d8",
          }}
        >
          <div className=" col-12  ">
            {/* <Loading /> */}

            <div className="d-flex  my-3 justify-content-between  align-items-center ">
              <div className="course-progress">
                <div
                  className="progress bg-primary"
                  style={{ width: `${props?.progressPercentage}%` }}
                ></div>
              </div>
              <div className="progress-percentage color-primary text-center px-2 py-2">
                <span> {Math.round(props?.progressPercentage)}%</span>
              </div>
            </div>
            <div className="text-center">
              <Trans>uploading-lecture-in-progress</Trans>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div
      className=" p-4"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        background: "#e0e0e061",
        zIndex: 9,
      }}
    >
      <div
        className=" p-4 row "
        style={{
          position: "absolute",
          width: 440,
          minHeight: 250,
          background: "white",
          top: "calc(50% - 100px)",
          left: "calc(50% - 220px)",
          zIndex: 10,
          borderRadius: 8,
          boxShadow: "0 11px 34px 0 #d8d8d8",
        }}
      >
        <div className=" p-4 row ">
          <div className="col-12 p-0  d-flex align-items-center justify-content-center">
            <h4>
              <Trans>lecture-added-successfully</Trans>
            </h4>
          </div>
          <div className="col-12 p-0  d-flex align-items-center justify-content-center">
            <button onClick={() => props.close()} className="btn btn-custom">
              <Trans>back</Trans>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
