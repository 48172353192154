import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { getStatistics } from "../../redux/reducers";

import "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
import { ReactComponent as HomeSvg } from "../../assets/svgs/home.svg";
import { ReactComponent as CardSvg } from "../../assets/svgs/card.svg";
import { ReactComponent as SettingsSvg } from "../../assets/svgs/setting.svg";
import { ReactComponent as LocationSvg } from "../../assets/svgs/location.svg";
import { Link, useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom" as const,
    },
    title: {
      display: true,
      text: localStorage.lang === 'ar' ? 'مخطط المستخدمين و الزوار' : "users and guests chart",
    },
  },
};

const Statistics = (props: any) => {
  const url = useLocation().pathname.substring(1);
  const [statistics, setStatistics]: any = useState({});
  const lang = localStorage.lang; // language detector

  const data = {
    labels: [""],
    datasets: [
      {
        label: lang === 'ar' ? 'الزوار المسجلين' : "registered guests",
        data: [statistics.registeredGuests],
        backgroundColor: "#666",
      },
      {
        label: lang === 'ar' ? 'الزوار غير المسجلين' : "unregistered guests",
        data: [statistics.unregisteredGuests],
        backgroundColor: "#888",
      },
      {
        label: lang === 'ar' ? 'المستخدمين المصدقين' : "verified users",
        data: [statistics.totalVerifiedUsers],
        backgroundColor: "#aaa",
      },
      {
        label: lang === 'ar' ? 'المستخدمين غير المصدقين' : "unverified users",
        data: [statistics.totalUnverifiedUsers],
        backgroundColor: "#ccc",
      },
      {
        label: lang === 'ar' ? 'إجمالي المستخدمين' : "total users",
        data: [statistics.totalUsers],
        backgroundColor: "#444444ff",
      },
    ],
  };

  const data2 = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "First dataset",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
      },
      {
        label: "Second dataset",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
      },
    ],
  };

  useEffect(() => {
    if (props?.statistics) {
      setStatistics(props?.statistics);
    }
  }, [props]);

  useEffect(() => {
    props.getStatistics();
  }, []);

  return (
    <div>
      <Helmet>
        <title>صفحة الإحصائيات - منصة كبسولة</title>
      </Helmet>

      <div className="  my-3 inline-grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4  w-full">
        <HomeItem
          title={"courses"}
          text="approved-courses"
          link="courses?filter_by=approved"
          Icon={() => <i className="text-4xl fal fa-book  text-purple-600"></i>}
        />
        <HomeItem
          title={"courses"}
          text="unapproved-courses"
          link="courses?filter_by=disapproved"
          Icon={() => <i className="text-4xl fal fa-book  text-purple-600"></i>}
        />
        <HomeItem
          title={"certificate-requests"}
          text="certificate-requests"
          link="certificate-requests"
          Icon={() => (
            <i className="text-4xl fal fa-certificate  text-purple-600"></i>
          )}
        />
        <HomeItem
          title={"partners"}
          text="education-partners"
          link="partners"
          Icon={() => (
            <i className="text-4xl fal fa-building text-purple-600"></i>
          )}
        />
        <HomeItem
          title={"students"}
          text="students"
          link="students"
          Icon={() => <i className="text-4xl fal fa-users text-purple-600"></i>}
        />
      </div>

      <div className="flex pt-4 my-4">
        <div className=" w-full h-screen ">
          <Bar className="h-screen" options={options} data={data} />
        </div>
        {/* <div className=" w-1/2">
          <Line data={data2} options={options} />
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courses: state.Approval?.courses,
    statistics: state.AllHome.statistics,
  };
};

export default connect(mapStateToProps, {
  getStatistics,
})(Statistics);

const HomeItem = ({ title, Icon, text, link }) => {
  return (
    <Link
      to={link}
      className="bg-white cursor-pointer  shadow-sm  shadow-slate-50  overflow-hidden p-2  rounded-[10px] items-center justify-center hover:bg-green-700"
    >
      {/* icon */}
      <div className=" p-2">
        <Icon />
      </div>
      {/* text */}

      <div className=" p-2">
        <div className="text-lg font-bold text-gray-500">
          <Trans>{title}</Trans>
        </div>
        <span className="text-sm font-bold text-gray-500">
          <Trans>{text}</Trans>
        </span>
      </div>
    </Link>
  );
};

