import React, { useEffect, useState } from "react";
// import { insCourse } from "../../redux/actions/CoursesAction";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { getApprovedCourses, courseApproval } from "../../redux/reducers";
import { approvalActions } from "../../redux/reducers/approval.reducer";
import { Loading } from "../loading";
import { ApprovedCourses } from "./approved-courses-table";

const Statistics = (props: any) => {
  const [courses, setCourses]: any = useState([]);
  const [loading, setLoading]: any = useState(true);

  useEffect(() => {
    setCourses(props?.courses?.courses);
    setLoading(props.courses?.loading);
  }, [props.courses]);

  // console.log(props.courses);

  useEffect(() => {
    props.getApprovedCourses();
  }, []);

  function approval(
    status: boolean,
    reason: string,
    id: string,
    percent: number
  ) {
    props.courseApproval(status, reason, id, percent);
  }

  return (
    <div className="">
      <Helmet>
        <title>صفحة الإحصائيات - منصة كبسولة</title>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <ApprovedCourses
          updateCapsula={props.updteCapsula}
          courses={courses}
          courseApproval={approval}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courses: state.Approval,
  };
};

export default connect(mapStateToProps, {
  getApprovedCourses,
  courseApproval,
  ...approvalActions,
})(Statistics);

{
  /*  */
}
//
