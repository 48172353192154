import i18n from "i18next";
// import Backend from 'i18next-http-backend'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from "react-i18next";
import * as L from "../locales";

const resources = {
  ar: {
    translation: L.Landing_ar,
  },
  en: {
    translation: L.Landing_en,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  debug: false,
  detection: {
    order: ["queryString", "cookie"],
    cache: ["cookie"],
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
