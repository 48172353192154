import React, { useEffect, useState } from "react";
// import { insCourse } from "../../redux/actions/CoursesAction";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  getUnapprovedCourses,
  clearApproval,
  courseApproval,
} from "../../redux/reducers";
import { DashboardLoading as Loading } from "../common/loading";
import { ApprovalTable } from "./approve-course-table";
import "./instructor.css";

const Statistics = (props: any) => {
  const [courses, setCourses]: any = useState([]);
  const [loading, setLoading]: any = useState(true);

  useEffect(() => {
    setCourses(props?.courses?.courses);
    setLoading(props.courses?.loading);
  }, [props.courses]);

  // console.log(props.courses);

  useEffect(() => {
    props.getUnapprovedCourses();
  }, []);

  function approval(
    status: boolean,
    reason: string,
    id: string,
    percent: number
  ) {
    props.courseApproval(status, reason, id, percent);
  }

  // useEffect(() => () => props.clearApproval());

  return (
    <div className="">
      <Helmet>
        <title>صفحة الإحصائيات - منصة كبسولة</title>
      </Helmet>
      {loading ? (
        <Loading isLoading={true} />
      ) : (
        <ApprovalTable courses={courses} courseApproval={approval} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courses: state.Approval,
  };
};

export default connect(mapStateToProps, {
  getUnapprovedCourses,
  // clearApproval,
  courseApproval,
})(Statistics);
