import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
// import { Loading } from "../loading";
import { connect } from "react-redux";
import { categoriesActions } from "../../redux/reducers/categories.reducer";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Table } from "../../components/shared/Table";
import { settingsActions } from "../../redux/settings.duck";
import { coursesActions } from "../../redux/courses.duck";
const Categories = (props: any) => {
  const [addCatWindow, toggleCatWindow] = useState(false);
  const [discountWindow, toggleDiscountWindow] = useState(false);
  const [item, setItem]: any = useState(null);
  const [countries, setCountries]: any = useState(null);

  const { handleSubmit, setValue, register } = useForm();
  useEffect(() => {
    props.getPromosions();
    props.getApprovedCourses();
  }, []);

  useEffect(() => {
    // setCountries(props.countries);
  }, [props.countries]);

  const addPromotion = (data: any) => {
    const endsAt = new Date(`${data.endDate} ${data.endTime}`).toISOString();

    delete data.endDate;
    delete data.endTime;
    data.endsAt = endsAt;
    // data.discountValue = +data.discountValue;
    // console.log(data.posterImage[0]);
    //
    const formData: any = new FormData();

    formData.append("discountValue", 0);
    formData.append("takenFromAccount", "kpsolla");
    formData.append("url", data.url);
    formData.append("posterImage", data.posterImage[0]);
    formData.append("endsAt", data.endsAt);

    props.createPromosions(formData);
    toggleCatWindow(false);
  };

  const addDiscount = (data: any) => {
    const endsAt = new Date(`${data.endDate} ${data.endTime}`).toISOString();

    delete data.endDate;
    delete data.endTime;
    data.endsAt = endsAt;
    data.discountValue = +data.discountValue;
    //
    const formData: any = new FormData();

    formData.append("discountValue", data.discountValue);
    formData.append("takenFromAccount", data.takenFromAccount);
    formData.append("url", data.url);
    formData.append("course", data.course);
    formData.append("posterImage", data.posterImage[0]);
    formData.append("endsAt", data.endsAt);

    props.createPromosions(formData);
    toggleDiscountWindow(false);
  };

  const columns = [
    {
      dataField: "course.title",
      text: <Trans>course</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return <div>{row?.course?.title ?? "--"}</div>;
      },
    },
    {
      dataField: "discountValue",
      text: <Trans>Discount Value</Trans>,
      sort: true,
    },
    {
      dataField: "takenFromAccount",
      text: <Trans>From Account</Trans>,
      sort: true,
    },
    {
      dataField: "endsAt",
      text: <Trans>Expiration</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return <div>{new Date(row.endsAt).toLocaleString()}</div>;
      },
    },
    {
      dataField: "isExpired",
      text: <Trans>Is Expired</Trans>,
      sort: false,
    },
  ];

  return (
    <div>
      <div className="">
        <Modal
          centered
          onHide={() => toggleCatWindow(false)}
          show={addCatWindow}
        >
          <Modal.Header>
            <h3>إضافة ترويج للدورة</h3>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(addPromotion)}>
              <div className="row text-right">
                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor="">رابط العرض</label>
                  </div>
                  <input
                    {...register("url", { required: true })}
                    type="text"
                    className="col-12"
                    placeholder="https://url.com/"
                  />
                </div>

                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor="">صورة البوستر</label>
                  </div>
                  <input
                    {...register("posterImage", { required: true })}
                    type="file"
                    accept="image/*"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor=""> صالح حتى تاريخ / وقت</label>
                  </div>
                  <div className="d-flex">
                    <input
                      {...register("endDate", { required: true })}
                      type="date"
                      className="col-6"
                    />
                    <input
                      {...register("endTime", { required: true })}
                      type="time"
                      className="col-6"
                    />
                  </div>
                </div>

                <div className="input-container col-12 my-4">
                  <button className="btn btn-primary col-12 text-center">
                    حفظ
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        {/* discount modal */}
        <Modal
          centered
          onHide={() => toggleDiscountWindow(false)}
          show={discountWindow}
        >
          <Modal.Header>
            <h3>إضافة تخفيض للدورة</h3>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(addDiscount)}>
              <div className="row text-right">
                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor="">نسبة التخفيض</label>
                  </div>
                  <input
                    {...register("discountValue", { required: true })}
                    type="number"
                    className="col-12"
                    placeholder="20%"
                  />
                </div>

                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor="">رابط العرض</label>
                  </div>
                  <input
                    {...register("url", { required: true })}
                    type="text"
                    className="col-12"
                    placeholder="https://url.com/"
                  />
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor="">الكورس</label>
                  </div>

                  <select
                    className="col-12 form-control"
                    defaultValue="kpsolla"
                    {...register("course", { required: true })}
                  >
                    {props?.courses?.map((item) => {
                      return (
                        <option key={item.id} value={item?.id}>
                          {item?.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor="">من حساب</label>
                  </div>

                  <select
                    className="col-12 form-control"
                    defaultValue="kpsolla"
                    {...register("takenFromAccount", { required: true })}
                  >
                    <option value="kpsolla">kpsolla</option>
                    {/* <option value="institute">institute</option> */}
                    <option value="instructor">course owner</option>
                  </select>
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor="">صورة البوستر</label>
                  </div>
                  <input
                    {...register("posterImage", { required: true })}
                    type="file"
                    accept="image/*"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor=""> صالح حتى تاريخ / وقت</label>
                  </div>
                  <div className="d-flex">
                    <input
                      {...register("endDate", { required: true })}
                      type="date"
                      className="col-6"
                    />
                    <input
                      {...register("endTime", { required: true })}
                      type="time"
                      className="col-6"
                    />
                  </div>
                </div>

                <div className="input-container col-12 my-4">
                  <button className="btn btn-primary col-12 text-center">
                    حفظ
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="">
        <div className="">
          <div className="">
            {props.paymentRequests?.length < 1 ? (
              <div className="text-center">
                <Trans>no-content</Trans>
              </div>
            ) : (
              <Table
                withSearch={true}
                data={props?.promotions || []}
                columns={columns}
              >
                <button
                  onClick={() => toggleDiscountWindow(true)}
                  type="submit"
                  className="btn btn-primary"
                >
                  إضافة تخفيض
                </button>
                <button
                  onClick={() => toggleCatWindow(true)}
                  type="submit"
                  className="btn btn-primary mx-1"
                >
                  إضافة ترويج لدورة
                </button>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  // console.log(state.Settings);

  return {
    // categories: state.Categories.categories,
    courses: state.Courses.courses,
    promotions: state.Courses.promotions,
    // approve: state.Approval.approveWithdrawalRequest,
  };
};

export default connect(mapStateToProps, {
  ...coursesActions,
})(Categories);
