import { GlobalServiceAdapter } from "../helpers/services";

const GET_COUNTRIES = "SETTINGS/GET_COUNTRIES";
const LOADING = "SETTINGS/LOADING";
const STOP_LOADING = "SETTINGS/STOP_LOADING";

export default function (state, { type, payload }) {
  switch (type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case STOP_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_COUNTRIES: {
      return {
        ...state,
        loading: false,
        countries: payload,
      };
    }
    default: {
      return {
        ...state,
        loading: false,
      };
    }
  }
}

const getCountries = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/settings/countries"
    );

    dispatch({ type: GET_COUNTRIES, payload: data });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};

const addCountry = (info: any) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await GlobalServiceAdapter.sendData("admin/settings/countries", info);

    getCountries()(dispatch);
    // dispatch({ type: GET_COUNTRIES, payload: data });
  } catch (err) {
    dispatch({ type: STOP_LOADING });
    //
  }
};

export const settingsActions = {
  getCountries,
  addCountry,
};
