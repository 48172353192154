import { APIProxy } from "../../helpers/proxy";
import { AnyAction } from "redux";
import notify from "../../components/common/notification-system/notification";
import { GlobalServiceAdapter } from "../../helpers/services";

/*********************************************/
/*********************************************/
/****************ACTION TYPES*****************/
/*********************************************/
/*********************************************/

const GET_UNAPPROVED_COURSE = "GET_UNAPPROVED_COURSE";

const GET_INSTITUTES = "GET_INSTITUTES";

const GET_COURSE_LECTURES = "GET_COURSE_LECTURES";

const GET_WITHDRAWAL_REQUESTS_SUCCESS = "GET_WITHDRAWAL_REQUESTS_SUCCESS";

const APPROVE_WITHDRAWAL_REQUEST_SUCCESS = "APPROVE_WITHDRAWAL_REQUEST_SUCCESS";

const ERRORS = "ERRORS";

const APPROVE_INSTITUTE = "APPROVE_INSTITUTE";
const DISAPPROVE_INSTITUTE = "DISAPPROVE_INSTITUTE";

const CLEAR_APPROVAL = "CLEAR_APPROVAL";

const COURSE_APPROVAL = "COURSE_APPROVAL";

const GET_MANUAL_PAYMENT_REQUESTS = "GET_MANUAL_PAYMENT_REQUESTS";

/*********************************************/
/*********************************************/
/*************ACTION DISPATCHERS**************/
/*********************************************/
/*********************************************/

/*********************************************/
/*********************************************/
/***************REDUCER ENTITY****************/
/*********************************************/
/*********************************************/

const initialize = {
  errors: null,
  courses: [],
  lectures: null,
  institutes: [],
  withdrawalRequest: [],
  approveWithdrawalRequest: null,
  approvalDone: false,
  loading: false,
};

export function Approval(state = initialize, { type, payload }: AnyAction) {
  switch (type) {
    case "LOADING": {
      return { ...state, loading: true };
    }
    case GET_UNAPPROVED_COURSE: {
      return { ...state, errors: null, courses: payload, loading: false };
    }
    case ERRORS: {
      return { ...state, errors: payload, loading: false };
    }
    case GET_COURSE_LECTURES: {
      return { ...state, errors: null, lectures: payload };
    }
    case GET_INSTITUTES: {
      return {
        ...state,
        errors: null,
        loading: false,
        institutes: payload?.institutes,
      };
    }
    case APPROVE_INSTITUTE: {
      const newState = state.institutes?.map((item: any) => {
        if (payload.id == item.id) {
          return { ...item, approved: payload.approved };
        }
        return item;
      });
      return { ...state, institutes: newState, loading: false };
    }
    case GET_WITHDRAWAL_REQUESTS_SUCCESS: {
      return { ...state, errors: null, withdrawalRequest: payload };
    }

    case APPROVE_WITHDRAWAL_REQUEST_SUCCESS: {
      const array = state.withdrawalRequest?.filter((item: any) => {
        if (payload.toString() !== item.id.toString()) {
          return item;
        }
      });
      return { ...state, errors: null, withdrawalRequest: array };
    }

    // case CLEAR_APPROVAL: {
    //   return {
    //     errors: null,
    //     courses: null,
    //     institutes: null,
    //   };
    // }
    case COURSE_APPROVAL: {
      return { ...state };
    }

    case GET_MANUAL_PAYMENT_REQUESTS: {
      return {
        ...state,
        loading: false,
        paymentRequests: payload,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export const getUnapprovedCourses = () => async (dispatch: any) => {
  dispatch({ type: "LOADING" });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/disapprovedCourses"
    );
    // console.log(data.courses);

    dispatch({ type: GET_UNAPPROVED_COURSE, payload: data?.courses });
  } catch (err) {
    // dispatch({ type: ERRORS, payload: err.data });
  }
};
export const getApprovedCourses = () => async (dispatch: any) => {
  dispatch({ type: "LOADING" });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/approvedCourses"
    );
    // console.log(data.courses);

    dispatch({ type: GET_UNAPPROVED_COURSE, payload: data.courses });
  } catch (err) {
    // dispatch({ type: ERRORS, payload: err.data });
  }
};

export const clearApproval = () => async (dispatch: any) => {
  dispatch({ type: CLEAR_APPROVAL });
};

export const getInstitutes = () => async (dispatch: any) => {
  dispatch({ type: "LOADING" });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/institutes"
    );
    // console.log(data.institutes);

    dispatch({ type: GET_INSTITUTES, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS, payload: err.data });
  }
};

export const courseApproval =
  (status: boolean, reason: any, courseID: string, percent: number) =>
  async (dispatch: any) => {
    dispatch({ type: "LOADING" });
    if (status) {
      await GlobalServiceAdapter.updateData(
        "admin/approval/approveCourse/" + courseID,
        {
          companyPercent: percent,
        }
      );
      getUnapprovedCourses()(dispatch);
      // dispatch({ type: COURSE_APPROVAL });
    } else {
      await GlobalServiceAdapter.sendData(
        "admin/approval/disApproveCourse/" + courseID,
        {
          reason: reason || "course disapproved !",
        }
      );
      getApprovedCourses()(dispatch);
      // dispatch({ type: COURSE_APPROVAL });
    }
  };

export const courseLectures = (courseID: string) => async (dispatch: any) => {
  dispatch({ type: "LOADING" });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `courses/${courseID}/lectures/`
    );
    dispatch({ type: GET_COURSE_LECTURES, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS, payload: err.data });
  }
};

export const instituteApproval =
  (status: boolean, reason: any, instituteID: string) =>
  async (dispatch: any) => {
    dispatch({ type: "LOADING" });
    if (status) {
      const data = await GlobalServiceAdapter.updateData(
        "admin/approval/approveInstitute/" + instituteID,
        {
          reason: reason || "institute approved successfully !",
        }
      );
      dispatch({ type: APPROVE_INSTITUTE, payload: data.data });
    } else {
      const data = await GlobalServiceAdapter.updateData(
        "admin/approval/disapproveInstitute/" + instituteID,
        {
          reason: reason,
        }
      );
      dispatch({ type: APPROVE_INSTITUTE, payload: data.data });
    }
  };

export const getAllwithdrawalRequests = () => async (dispatch: any) => {
  dispatch({ type: "LOADING" });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `payments/earns/withdraw-requests`
    );
    dispatch({ type: GET_WITHDRAWAL_REQUESTS_SUCCESS, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS, payload: err.data });
  }
};

export const withdrawalApprove = (response: any) => async (dispatch: any) => {
  dispatch({ type: "LOADING" });
  try {
    await GlobalServiceAdapter.updateData(
      "payments/approve-withdraw-request",
      response
    );

    dispatch({
      type: APPROVE_WITHDRAWAL_REQUEST_SUCCESS,
      payload: response.id,
    });
    notify("نجحة عملية السحب", "تم سحب المبلغ بنجاح", "success");
  } catch (err) {
    notify("خطأ", err.response?.data?.message, "danger");
  }
};
const getUnprocessedManualRequests = () => async (dispatch: any) => {
  dispatch({ type: "LOADING" });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "admin/reports/manual-payments/unprocessed"
    );
    dispatch({ type: "GET_MANUAL_PAYMENT_REQUESTS", payload: data });
  } catch (err) {
    //
  }
};

const approveManualPayment = (id) => async (dispatch: any) => {
  try {
    await GlobalServiceAdapter.updateData(
      "admin/approval/manual-payments/approve-request/" + id,
      {}
    );
    getUnprocessedManualRequests()(dispatch);
  } catch (err) {
    console.log(err);
  }
};

const updteCapsula = (id, info) => async (dispatch: any) => {
  try {
    const data = await GlobalServiceAdapter.updateFiles(
      "courses/" + id + "/capsula/update",
      info
    );
    console.log(data);

    // getUnprocessedManualRequests()(dispatch);
  } catch (err) {
    console.log(err);
  }
};

const rejectManualPayment = (id, info) => async (dispatch: any) => {
  try {
    await GlobalServiceAdapter.updateData(
      "admin/approval/manual-payments/disapprove-request/" + id,
      info
    );
    getUnprocessedManualRequests()(dispatch);
  } catch (err) {
    console.log(err);
  }
};

export const approvalActions = {
  getUnprocessedManualRequests,
  approveManualPayment,
  updteCapsula,
  rejectManualPayment,
};
