import { configs } from "../configs";
import { APIProxy } from "./proxy";
import { storage } from "./storage.adapter";

class APIService {
  private readonly api: string;
  constructor(api: string) {
    this.api = api;
  }

  private token = storage.useLocalStorage().getItem("accessToken");

  private jsonHeaders = {
    "Content-Type": "application/json",
    Authorization: `bearer ${this.token}`,
  };

  private formDataHeaders = {
    "Content-Type": "application/json",
    Authorization: `bearer ${this.token}`,
  };

  public async fetchData(route: string = ""): Promise<any> {
    return await APIProxy("GET", this.api + route, null, this.jsonHeaders);
  }

  public async sendData(route: string, data: object): Promise<any> {
    return await APIProxy("POST", this.api + route, data, this.jsonHeaders);
  }

  public async updateData(route: string, data: any): Promise<any> {
    return await APIProxy("PUT", this.api + route, data, this.jsonHeaders);
  }
  public async sendFiles(route: string, data: any): Promise<any> {
    return await APIProxy("POST", this.api + route, data, this.formDataHeaders);
  }

  public async deleteData(route: string): Promise<any> {
    return await APIProxy("DELETE", this.api + route, null, this.jsonHeaders);
  }

  public async updateFiles(route: string, data: object): Promise<any> {
    return await APIProxy("PUT", this.api + route, data, this.formDataHeaders);
  }
  async downloadFile(route: string = ""): Promise<any> {
    return await APIProxy(
      "GET",
      this.api + route,
      null,
      this.jsonHeaders,
      "blob"
    );
  }
}

export const GlobalServiceAdapter: APIService = new APIService(
  `${configs.api}/api/v1/`
);

export const GlobalServiceAdapterV2: APIService = new APIService(
  `${configs.api}/api/v2/`
);

export const ServiseAdapterCustomVersion: APIService = new APIService(
  `${configs.api}/api/`
);
