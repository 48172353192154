import React, { useState, useEffect } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { ModalPlayer } from "../common/modal";
import { useForm } from "react-hook-form";
export const ApprovedCourses = (props: {
  courses: Array<any>;
  courseApproval: any;
  updateCapsula: any;
}) => {
  const [file, setFile] = useState(null);
  const [item, setItem] = useState(null);
  const [oneCourse, setOneCourse] = useState([]);
  const [showCapsula, setShowCapsula] = useState(false);
  const { handleSubmit, register } = useForm();
  // useEffect(() => {
  // }, []);

  const onUpdateCapsula = (data) => {
    // console.log(file);

    // return;

    const form = new FormData();

    form.append("video", file);
    props.updateCapsula(item.id, form);
    setItem(null);
    // ...
  };
  return (
    <div>
      <div className="course-tables">
        <div className="float-right">
          <h3>الكورسات المقبولة </h3>
          <br></br>
        </div>
        <Table className="the-table" responsive bordered hover>
          <thead>
            <tr>
              <th>اسم الكورس</th>

              <th>تاريخ الإنشاء</th>
              <th>
                <i className="far fa-videp" /> كبسولة
              </th>
              <th>
                <i className="far fa-video" /> المحاضرات
              </th>
              <th>
                <i className="far fa-gear" /> اعدادات
              </th>
            </tr>
          </thead>
          {props.courses &&
            props.courses.map((course, idx) => {
              return (
                <tbody key={idx}>
                  <tr>
                    <td className="align-middle" title={course.title}>
                      <a
                        target="_blank"
                        href={`https://kpsolla.com/visit?id=${course.id}`}
                      >
                        {course.title}
                      </a>
                    </td>

                    {/* <td className="align-middle" title={course.descriptions}>
                      {course.descriptions.slice(0, 18) + "..."}
                    </td> */}
                    <td className="align-middle">
                      {new Date(course.createdAt).toLocaleDateString()}
                    </td>
                    <td className="align-middle">
                      {course.capsula ? (
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setShowCapsula(true);
                            setOneCourse(course);
                          }}
                        >
                          مشاهدة
                        </Button>
                      ) : (
                        <span>لا يوجد كبسولة</span>
                      )}
                    </td>
                    <td>
                      <div className="m-1">
                        <Button
                          variant="secondary"
                          onClick={() =>
                            (window.location.href =
                              "/courses/lectures/" + course.id)
                          }
                        >
                          المحاضرات
                        </Button>
                      </div>
                    </td>
                    <td style={{ position: "relative" }}>
                      <div className="d-flex text-center">
                        <div className="m-1 d-flex ">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              setItem(course);
                            }}
                          >
                            تعديل الكبسولة
                          </button>

                          <Button
                            variant="danger"
                            onClick={() =>
                              props.courseApproval(
                                false,
                                "your course has been disapproved by system !!",
                                course.id
                              )
                            }
                          >
                            توقيف{" "}
                          </Button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          <ModalPlayer
            course={oneCourse}
            show={showCapsula}
            handleClose={() => setShowCapsula(false)}
          />

          <Modal show={item ? true : false} centered>
            <Modal.Body>
              <form
                action=""
                className=""
                onSubmit={handleSubmit(onUpdateCapsula)}
              >
                <div className="py-2 row m-0">
                  <label>اختيار ملف الفيديو للكبسولة</label>
                  <input
                    // {...register("video", { required: true })}
                    onChange={({ target }) => {
                      setFile(target.files[0]);
                    }}
                    type="file"
                    name=""
                    className=" col-12"
                    id=""
                  />
                </div>

                <div className="py-2 row m-0">
                  <button className="btn btn-primary">حفظ</button>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </Table>
      </div>
    </div>
  );
};
