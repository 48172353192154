import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Loading } from "../../components/loading";
import { Table } from "../../components/shared/Table";
import { configs } from "../../configs";
import i18n from "../../configs/i18n";
import { approvalActions } from "../../redux/reducers/approval.reducer";

const ManualPaymentRequests = (props: any) => {
  const columns = [
    {
      dataField: "user.username",
      text: <Trans>username</Trans>,
      sort: true,
    },
    {
      dataField: "course.title",
      text: <Trans>course</Trans>,
      sort: true,
    },
    {
      dataField: "createdAt",
      text: <Trans>requested-at</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return <div>{new Date(row.createdAt).toDateString()}</div>;
      },
    },
    {
      dataField: "attachment",
      text: <Trans>attachment</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            <button onClick={() => setItem(row)} className="btn btn-primary">
              عرض الايصال
            </button>
            {/* <img
            onClick={() => setItem(row)}
            className="btn p-0 m-0"
            width="40"
            src={`${configs.storage_api}/${row.attachment}`}
            alt=""
            /> */}
          </div>
        );
      },
    },
    {
      dataField: "course.createdAt",
      text: <Trans>options</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            <button
              onClick={() => approveManualRequest(row)}
              className="btn btn-primary mx-1"
            >
              approve
            </button>
            <button
              onClick={() => rejectManualRequest(row)}
              className="btn btn-danger mx-1"
            >
              reject
            </button>
          </div>
        );
      },
    },
  ];

  const [item, setItem] = useState(null);
  useEffect(() => {
    props.getUnprocessedManualRequests();
  }, []);

  useEffect(()=>{
    console.log(props.paymentRequests);
    
  },[props.paymentRequests])

  const approveManualRequest = (data: any) => {
    // console.log(data);
    const accept = window.confirm("are you sure to accept this request");
    if (accept) {
      props.approveManualPayment(data.id);
    } else {
      return;
    }
  };

  const rejectManualRequest = (data: any) => {
    const msg = window.prompt(i18n.t("reject-reason"));

    if (msg) {
      props.rejectManualPayment(data.id, { reason: msg });
    }

    setItem(null);
  };
  if (props.loading) return <Loading />;
  return (
    <section className="">
      <Modal onHide={() => setItem(null)} show={item ? true : false} centered>
        <Modal.Body>
          <div className="row  ">
            <div className="col-12 d-flex justify-content-center">
              <img
                width="250"
                src={`${configs.storage_api}/${item?.attachment}`}
                // src="https://dev_storage.kpsolla.com/images/attachments/45774742296619264_photo_2021-11-24-09.20.36.jpeg"
                alt=""
              />
            </div>

            <div className="col-12 d-flex justify-content-center my-3">
              <button
                onClick={() => approveManualRequest(item)}
                className="btn btn-primary mx-1"
              >
                approve
              </button>
              <button
                onClick={() => rejectManualRequest(item)}
                className="btn btn-danger mx-1"
              >
                reject
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="" dir="auto">
        <div className="">
          <div className="">
            {props.paymentRequests?.length < 1 ? (
              <div className="text-center">
                <Trans>no-content</Trans>
              </div>
            ) : (
              <Table
                withSearch={true}
                data={props.paymentRequests || []}
                columns={columns}
                orderBy="createdAt"
              ></Table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  //   console.log(state.Approval);
  return {
    paymentRequests: state.Approval?.paymentRequests,
    loading: state.Approval?.loading,
  };
};

export default connect(mapStateToProps, { ...approvalActions })(
  ManualPaymentRequests
);
