import { ReactComponent as SearchSvg } from "../../assets/svgs/search.svg";
import arLogo from "../../assets/logos/capsula.png";
import { Input } from "../shared/Input";

const Navbar = () => {
  return (
    <div className=" p-3">
      <div className=" p-2">
        <div className="flex justify-between items-center p-2">
          <div className=" w-full sm:w-[300px] flex items-center">
            <div
              onClick={() => {
                const item = document.getElementById("sidebar-wrapper");

                item.classList.toggle("mr-[-140px]");
              }}
              className=" cursor-pointer text-[20px] flex items-center"
            >
              <i className="fa text-gray-400 fa-bars"></i>
            </div>
            <div className="mx-3">
              <Input placeholder="بحث..." icon={SearchSvg} />
            </div>
          </div>
          <div className="flex items-center  sm:block hidden">
            <img src={arLogo} alt="" className="w-[100px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
