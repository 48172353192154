import React from "react";
import { Redirect, Route } from "react-router-dom";
import { systemStatus } from "../helpers/auth.status";

interface RouterI {
  path: string;
  exact: boolean;
  component: any;
}
export const AdminRouter = ({ path, exact, component, ...rest }: RouterI) => {
  if (systemStatus.getAuthStatus()) {
    return <Route exact path={path} component={component} {...rest} />;
  }
  return <Redirect to="/login" />;
};
