import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Link, Redirect, useLocation } from "react-router-dom";
import { Loading } from "../../components/loading";
import { Table } from "../../components/shared/Table";
import { usersActions } from "../../redux/users.duck";
import { extractQuery } from "../../util/extracter";

const Customers = (props: any) => {
  const query: any = extractQuery(useLocation().search);
  const columns = [
    {
      dataField: "studentName",
      text: <Trans>username</Trans>,
      sort: true,
    },
    {
      dataField: "student.email",
      text: <Trans>email</Trans>,
      sort: true,
    },
    {
      dataField: "course.title",
      text: <Trans>course</Trans>,
      sort: true,
    },

    {
      dataField: "createdAt",
      text: <Trans>registered-at</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {new Date(row.createdAt).toLocaleDateString()} --{" "}
            {new Date(row.createdAt).toLocaleTimeString()}
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: <Trans>status</Trans>,
      sort: true,
      formatter: (cell, row) => {
        if (row.status === 0) {
          return (
            <div className="d-flex">
              <div
                style={{ borderRadius: "0" }}
                className="btn btn-danger px-3 py-1"
              >
                <Trans>on-hold</Trans>
              </div>
              <div
                onClick={() => {
                  props.updateCertificateRequests({ id: row.id, status: 1 });
                  setRedirect("in-progress");
                }}
                style={{ borderRadius: "0" }}
                className="btn btn-danger border-right py-1 d-flex align-items-center"
              >
                <i className="fa fa-caret-left"></i>
              </div>
            </div>
          );
        }
        if (row.status === 1) {
          return (
            <div className="d-flex">
              <div
                style={{ borderRadius: "0" }}
                className="btn btn-info px-3 py-1"
              >
                <Trans>in-progress</Trans>
              </div>
              <div
                onClick={() => {
                  props.updateCertificateRequests({ id: row.id, status: 2 });
                  setRedirect("issued");
                }}
                style={{ borderRadius: "0" }}
                className="btn btn-info border-right py-1 d-flex align-items-center"
              >
                <i className="fa fa-caret-left"></i>
              </div>
            </div>
          );
        }
        if (row.status === 2) {
          return (
            <div className="d-flex">
              <div
                style={{ borderRadius: "0" }}
                className="btn btn-primary px-3 py-1"
              >
                <Trans>issued</Trans>
              </div>
              <div
                onClick={() => {
                  props.updateCertificateRequests({ id: row.id, status: 3 });
                  setRedirect("delivered");
                }}
                style={{ borderRadius: "0" }}
                className="btn btn-primary border-right py-1 d-flex align-items-center"
              >
                <i className="fa fa-caret-left"></i>
              </div>
            </div>
          );
        }
        if (row.status === 3) {
          return (
            <div className="d-flex">
              <div
                style={{ borderRadius: "0" }}
                className="btn btn-success px-3 py-1"
              >
                <Trans>delivered</Trans>
              </div>
            </div>
          );
        }
      },
    },
  ];

  const statuses = [
    { status: 0, title: "on-hold" },
    { status: 1, title: "in-progress" },
    { status: 2, title: "issued" },
    { status: 3, title: "delivered" },
  ];

  const [redirect, setRedirect] = useState(null);
  useEffect(() => {
    let sts = statuses.find((item) => item.title === query.status);
    if (!sts) {
      sts = { status: 0, title: "on-hold" };
    }
    props.getCertificateRequests(sts.status);
  }, []);

  useEffect(() => {
    let sts = statuses.find((item) => item.title === query.status);
    if (!sts) {
      sts = { status: 0, title: "on-hold" };
    }
    props.getCertificateRequests(sts.status);
    setRedirect(null);
  }, [query.status]);

  useEffect(() => {
    let sts = statuses.find((item) => item.title === redirect);
    if (!sts) {
      sts = { status: 0, title: "on-hold" };
    }
    props.getCertificateRequests(sts?.status);
    setRedirect(null);
  }, [redirect]);

  return (
    <section className="m-5 row">
      {redirect && <Redirect to={`/certificate-requests?status=${redirect}`} />}
      <div className="col-12">
        <div className="instructor-courses">
          <div className="course-tables">
            {props.paymentRequests?.length < 1 ? (
              <div className="text-center">
                <Trans>no-content</Trans>
              </div>
            ) : (
              <Table
                withSearch={true}
                data={props.requests || []}
                columns={columns}
              >
                <Link
                  to={"?status=on-hold"}
                  className={`btn ${
                    query.status === "on-hold" || !query.status
                      ? "btn-danger"
                      : "border"
                  }`}
                  style={{ borderRadius: 0 }}
                >
                  <Trans>on-hold</Trans>
                </Link>
                <Link
                  to={"?status=in-progress"}
                  className={`btn ${
                    query.status === "in-progress" ? "btn-info" : "border"
                  }`}
                  style={{ borderRadius: 0 }}
                >
                  <Trans>in-progress</Trans>
                </Link>
                <Link
                  to={"?status=issued"}
                  className={`btn ${
                    query.status === "issued" ? "btn-primary" : "border"
                  }`}
                  style={{ borderRadius: 0 }}
                >
                  <Trans>issued</Trans>
                </Link>
                <Link
                  to={"?status=delivered"}
                  className={`btn ${
                    query.status === "delivered" ? "btn-success" : "border"
                  }`}
                  style={{ borderRadius: 0 }}
                >
                  <Trans>delivered</Trans>
                </Link>
                <div
                  className={"btn mx-2 btn-primary"}
                  style={{ borderRadius: 0 }}
                  onClick={() => {
                    props.downloadRequests(
                      statuses.find((item) => item.title === query.status)
                    );
                  }}
                >
                  <Trans>download-csv</Trans>
                </div>
              </Table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  //   console.log(state.Approval);
  return {
    // paymentRequests: state.Approval?.paymentRequests,
    loading: state.Users?.loading,
    requests: state.Users?.requests,
  };
};

export default connect(mapStateToProps, { ...usersActions })(Customers);
