import React from 'react';
import { Table } from 'react-bootstrap';
import './instructor.css';

export const EarnsTable:any = (props:any) => {

  console.log(props?.earns);
  return (
    <>
    <div className="course-tables">
      <div className="float-right">
        <h3>{"جدول الحسابات و التوفير"}</h3>
        <br></br>
      </div>
      <Table className="the-table" responsive bordered hover>
        <thead>
          <tr>
            {/* <th>#</th> */}
            <th>
              <i className="far fa-tag" /> {'المجموع'}{' '}
              {/* edit happened changed form سحب  to عملية دفع */}
            </th>
            {/* <th>
              <i className="far fa-tag" /> {i18next.t("earns.save")}
            </th> */}
            <th>
              <i className="far fa-calendar" /> {'أخر دخل'}
            </th>
          </tr>
        </thead>
          <tbody>
          <tr>
            <td>{props?.earns?.totalEarns}</td>
            {/* <td>{earns.enrollments.}</td> */}
            <td>{props?.earns?.lastIncome ? new Date(props?.earns?.lastIncome).toLocaleString() : "N/A"}</td>
          </tr>
        </tbody>
      </Table>
    </div>

      {/* <EarnsRreport earns={props?.earns} /> */}

    </>
  );
};

// power this component with disabling comment of EarnsRreport
const EarnsRreport:any = (props:any) => {

  return (
    <>
     <div className="course-tables mt-4">
  <div className="float-right">
    <h3>{"تقرير الحسابات"}</h3>
    <br></br>
  </div>
  <Table className="the-table" responsive bordered hover>
    <thead>
      <tr>
        {/* <th>#</th> */}
        <th>
          <i className="far fa-tag" /> {"القيمة المتحصل عليها"}{' '}
          {/* edit happened changed form سحب  to عملية دفع */}
        </th>
        <th>
          <i className="far fa-calendar" /> {"زمن العملية"}
        </th>
      </tr>
    </thead>
    {props.earns?.enrollments?.map((value:any, idx:any) => {
      return (
      <tbody key={idx}>
      <tr>
        <td>{value.instructorAmount}</td>
        <td>{new Date(value.updatedAt).toLocaleString()}</td>
      </tr>
    </tbody>
    );
    
    })}
  </Table>
  </div>
    </>
  )
}
