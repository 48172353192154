import React, { useEffect, useState } from "react";
import { MediaPlayer } from "dashjs";
import { Modal } from "react-bootstrap";
// import { apiUrl } from "../../configs/config";
import { configs } from "../../configs";

export const ModalPlayer = (props) => {
  const [play, setPlay] = useState(false);

  const player = MediaPlayer().create();

  if (play) {
    setTimeout(() => {
      const link = `${configs.api}/api/v1/courses/${props.course.id}/capsula/watch/manifest.mpd`;
      player.initialize(document.querySelector("#video"), link, play);
    }, 1000);
  }

  useEffect(() => {
    if (props.show) {
      setPlay(true);
      // console.log(props.show);
    } else {
      setPlay(false);
      // console.log(props.show);
    }
  }, [props]);

  return (
    <>
      <Modal show={play} onHide={props.handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="p-0 m-0">
          {play && (
            <div className="p-0 m-0">
              <video
                id="video"
                autoPlay={true}
                className="course-card-video"
                style={{ width: "100%", height: "100%" }}
                controls
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
