import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { Loading } from "../common/loading";
import { connect } from "react-redux";
import {
  getAllwithdrawalRequests,
  withdrawalApprove,
} from "../../redux/reducers";

import "./draw.css";
import { IPN } from "../common/withdrawal-IPN";

const RequestTable = (props: any) => {
  const [wait, setWait] = useState(true);
  const [show, setShow] = useState(false);
  const [request, setRequest] = useState(false);

  useEffect(() => {
    if (props?.requests) {
      setWait(false);
    }
  }, [props]);

  useEffect(() => {
    props.getAllwithdrawalRequests();
  }, []);

  return (
    <div>
      {!wait ? (
        <div className="course-tables">
          <div className="float-right">
            <h3>طلبات موافقة السحب </h3>
            <br />
          </div>

          <Table className="the-table" responsive bordered hover>
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">المبلغ المراد سحبه</th>
                <th scope="col">اسم الاستاذ</th>
                <th scope="col">البطاقه</th>
                <th scope="col">تاريخ الطلب</th>
                <th scope="col"> خيارات</th>
              </tr>
            </thead>
            {props?.requests &&
              props?.requests?.map((request: any, idx: any) => {
                return (
                  <tbody key={idx}>
                    <tr>
                      <th scope="row">{Number(idx) + 1}</th>
                      <td>{request.earns.totalEarns}</td>
                      <td>
                        {request.earns.firstName} {request.user.lastName}
                      </td>
                      <td>{request.userBillingAccount.cardNumber}</td>
                      <td>{new Date(request.requestDate).toLocaleString()}</td>
                      <td>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setShow(true);
                            setRequest(request);
                          }}
                        >
                          موافقة
                        </button>
                      </td>
                    </tr>
                  </tbody>
                );
              })}

            {/* modal of request withdrawal */}
            <IPN
              request={request}
              show={show}
              approve={props.withdrawalApprove}
              handleClose={() => setShow(false)}
            />
          </Table>

          <IPN />
        </div>
      ) : (
        <Loading isLoading={wait} />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    requests: state.Approval.withdrawalRequest,
    approve: state.Approval.approveWithdrawalRequest,
  };
};

export default connect(mapStateToProps, {
  getAllwithdrawalRequests,
  withdrawalApprove,
})(RequestTable);
