import { useHistory } from "react-router-dom";
import ApprovedCourses from "./approved-courses";
import CourseApproval from "./course-approval";
const Courses = () => {
  const { location } = useHistory();

  if (location.search.includes("disapproved")) return <CourseApproval />;
  return <ApprovedCourses />;
};

export default Courses;
