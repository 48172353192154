import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Input } from "../shared/Input";
import { sessionActions } from "../../redux/reducers/session.reducer";
import { connect } from "react-redux";
import { configs } from "../../configs";
import { systemStatus } from "../../helpers/auth.status";

const SettingsSidebar = (props) => {
  const [personalInfo, togglePersonalInfo] = useState(false);
  const [passwords, togglePasswords] = useState(false);
  const [settingsBar, toggleSettingsBar] = useState(false);

  useEffect(() => {
    props.getUserInfo();
  }, []);

  return (
    // sidebar outter wrapper
    <div
      className={`hidden dark:bg-blue-100 transition-all relative ${
        !settingsBar ? "ml-[-290px]" : ""
      } lg:block   h-screen bg-gray-100 `}
    >
      {/* sidebar inner wrapper */}
      <div className="overflow-hidden h-full w-[290px] ">
        <div className=" px-2 relative h-full  overflow-x-scroll items-center">
          {/* profile info */}
          <div className="w-full flex justify-center py-2">
            <div className="w-[100px] border-2 border-gray-200 h-[100px] overflow-hidden rounded-full">
              <img
                src={`${configs.storage_api}/${props.user?.profileImage}`}
                alt=""
              />
            </div>
          </div>
          <div className=" w-full p-2 text-center">
            <span className="text-lg">
              {props.user?.firstName + " " + props.user?.lastName}{" "}
              <span className="text-gray-400 text-sm">(admin)</span>
            </span>
          </div>
          <ItemWrapper
            show={personalInfo}
            title={"البيانات الشخصية"}
            onClick={() => togglePersonalInfo(!personalInfo)}
          >
            <form action="" className="py-3">
              <Input
                placeholder="الاسم الاول"
                icon={() => <i className="fa text-gray-400 fa-user" />}
              />
              <Input
                placeholder="الاسم الاول"
                icon={() => <i className="fa text-gray-400 fa-user" />}
              />
              <button className="rounded-[20px] text-center text-white bg-blue-500 w-full py-2">
                <Trans>save</Trans>
              </button>
            </form>
          </ItemWrapper>
          <ItemWrapper
            show={passwords}
            title={"كلمة المرور"}
            onClick={() => togglePasswords(!passwords)}
          >
            <form action="" className="py-3">
              <Input
                placeholder="كلمة المرور الحالية"
                icon={() => <i className="fa text-gray-400 fa-lock" />}
              />
              <Input
                placeholder="كلمة المرور جديدة"
                icon={() => <i className="fa text-gray-400 fa-lock" />}
              />
              <Input
                placeholder="تأكيد كلمة المرور"
                icon={() => <i className="fa text-gray-400 fa-lock" />}
              />
              <button className="rounded-[20px] text-center text-white bg-blue-500 w-full py-2">
                <Trans>save</Trans>
              </button>
            </form>
          </ItemWrapper>
          <div className="absolute bottom-0 w-full left-0">
            <div className="   w-full">
              <div className="flex justify-between p-2 ">
                <div className="text-gray-500"> الوضع الليلي</div>

                <div className="bg-indigo-600 cursor-pointer px-2 rounded-[5px] text-white text-sm flex items-center">
                  <span>تفعيل</span>
                </div>
              </div>
            </div>
            <div className="   w-full">
              <div className="flex justify-between p-2 ">
                <div className="text-gray-500"> تسجيل خروج</div>

                <div
                  onClick={() => systemStatus.discardAuthData(true)}
                  className="bg-indigo-600 px-2 cursor-pointer rounded-[5px] text-white text-sm flex items-center"
                >
                  <span>تسجيل خروج</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => toggleSettingsBar(!settingsBar)}
        className={`absolute top-[48px] cursor-pointer opacity-90 ${
          settingsBar ? "right-[-25px]" : "right-[-40px]"
        } bg-indigo-600 shadow-lg w-[50px] h-[30px] rounded-[10px] flex justify-center items-center`}
      >
        <div className="text-[20px] text-white flex items-center">
          {settingsBar ? (
            <i className="fa  fa-angle-left"></i>
          ) : (
            <i className="fa  fa-cog"></i>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state?.Session?.userInfo,
  };
}

export default connect(mapStateToProps, { ...sessionActions })(SettingsSidebar);

const ItemWrapper = ({ children, show, onClick, title }) => {
  return (
    <div className="w-full my-1 cursor-pointer p-2  ">
      <div onClick={onClick} className="flex justify-between items-center">
        <div className="text-gray-400">{title}</div>
        <div className=" flex items-center">
          <i
            className={`fa text-gray-400 fa-angle-${show ? "down" : "left"}`}
          ></i>
        </div>
      </div>
      <div className={`${!show && "hidden"}`}>{children}</div>
    </div>
  );
};
