import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AdminRouter } from "./admin.routes";
import * as C from "../components";
import Layout from "../components/layout/layout";
import * as pages from "./../pages";
export const SystemRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={C.Login} />
        <Layout>
          <AdminRouter exact path="/" component={C.Home} />
          <AdminRouter exact path="/home" component={C.Home} />
          {/* <AdminRouter
            exact
            path="/statisticsTable"
            component={C.StatisticsTable}
          /> */}
          <AdminRouter exact path="/courses" component={C.Courses} />
          <AdminRouter
            exact
            path="/courses/lectures/:courseID"
            component={C.CourseLectures}
          />
          <AdminRouter exact path="/partners" component={pages.Partners} />
          <AdminRouter
            exact
            path="/partnerts"
            component={C.InstitutesApproval}
          />
          <AdminRouter exact path="/settings" component={pages.Settings} />
          <AdminRouter exact path="/countries" component={pages.Countries} />
          <AdminRouter exact path="/earns" component={C.Earns} />
          <AdminRouter exact path="/discounts" component={pages.Promosions} />
          <AdminRouter
            exact
            path="/certificate-requests"
            component={pages.CertificateRequests}
          />
          <AdminRouter
            exact
            path="/withdrawalRequest"
            component={C.RequestTable}
          />
          <AdminRouter exact path="/students" component={pages.Customers} />
          <AdminRouter
            exact
            path="/payments"
            component={pages.ManualPaymentRequests}
          />
          <AdminRouter exact path="/categories" component={C.Categories} />
        </Layout>
      </Switch>
    </Router>
  );
};
