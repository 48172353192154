export const Input = (props) => {
  return (
    <div
      className={
        props?.className ||
        "bg-white my-2 rounded-[20px] w-full  px-2 overflow-hidden flex items-center"
      }
    >
      {props.icon && (
        <div className="px-2">
          <props.icon />
        </div>
      )}
      <input
        required={props.required || true}
        onChange={({ target }) => props?.onChanged(target.value)}
        type={props.type || "text"}
        className="border-0 py-[10px] bg-transparent rounded-none px-0 sm:w-[200px]  md:w-[300px]  text-black"
        placeholder={props?.placeholder}
      />
    </div>
  );
};
