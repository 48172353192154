import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { connect } from "react-redux";
import {
  getAllStudents,
  getAllInstitutes,
  getAllCourses,
} from "../../redux/reducers";

const StatisticsTable = (props: any) => {
  const url = new URLSearchParams(window.location.search);

  const [courses, setCourses]: any = useState([]);
  const [students, setStudents]: any = useState([]);
  const [institutes, setInstitutes]: any = useState([]);

  useEffect(() => {
    if (props.home?.allCourses) {
      setCourses(props.home?.allCourses);
      //   console.log(courses);
    }
    if (props.home?.allInstitutes) {
      setInstitutes(props.home?.allInstitutes.institutes);
    }
    if (props.home?.allStudents) {
      setStudents(props.home.allStudents?.users);
    }
  }, [props]);

  useEffect(() => {
    props.getAllCourses();
    props.getAllStudents();
    props.getAllInstitutes();
  }, []);

  let param = url.get("type");

  return (
    <div>
      <div className="course-tables">
        <div className="float-right">
          <h3> البيانات </h3>
          <br></br>
        </div>
        <Table className="the-table" responsive bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>
                {" "}
                <i className="far fa-info" /> البيانات
              </th>
            </tr>
          </thead>

          {param === "courses" ? <Courses course={courses} /> : null}
          {param === "students" ? <Students student={students} /> : null}
          {param === "institutes" ? (
            <Institutes institute={institutes} />
          ) : null}
        </Table>
      </div>
    </div>
  );
};

// function NotFound(){
//     return (
//         <tbody>
//           <tr>
//             <td>
//               #
//             </td>
//             <td className="d-flex flex-row justify-content-center">
//                 <h1 className="text-secondary">Not Found</h1>
//             </td>
//           </tr>
//         </tbody>
//     )
// }

function Students(props: { student: any }) {
  return (
    props?.student &&
    props?.student?.map((stud: any, idx: number) => {
      return (
        <tbody key={idx}>
          <tr>
            <td>
              <strong>{idx + 1}</strong>
            </td>

            <td className="align-middle">
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> الإسم :</b>
                  <br />
                  <small>
                    {stud.firstName} {stud.lastName}
                  </small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> البريد الإلكتروني:</b>
                  <br />
                  <small>{stud.email}</small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> المدينة:</b>
                  <br />
                  <small>{stud.city}</small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> تاريخ الإنشاء: </b>
                  <small>
                    <i className="far fa-calendar" />{" "}
                    {new Date(stud.createdAt).toLocaleDateString()}
                  </small>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      );
    })
  );
}

function Institutes(props: { institute: any }) {
  return (
    props.institute &&
    props.institute.map((institute: any, idx: number) => {
      return (
        <tbody key={idx}>
          <tr>
            <td>
              <strong>{idx + 1}</strong>
            </td>
            <td className="align-middle">
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> الإسم :</b>
                  <br />
                  <small>{institute.instituteName}</small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> البريد الإلكتروني:</b>
                  <br />
                  <small>{institute.email}</small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> المدينة:</b>
                  <br />
                  <small>{institute.city}</small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> تاريخ الإنشاء: </b>
                  <small>
                    <i className="far fa-calendar" />{" "}
                    {new Date(institute.createdAt).toLocaleDateString()}
                  </small>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      );
    })
  );
}

function Courses(props: { course: any }) {
  if (props?.course?.courses) {
    return props.course?.courses?.map((course: any, idx: any) => {
      return (
        <tbody key={idx}>
          <tr>
            <td>
              <strong>{idx + 1}</strong>
            </td>

            <td className="align-middle">
              <div className="course-img d-flex">
                <a href={`kpsolla.com/courses/${course.id}`}>
                  <img src={`https://${course.courseImage}`} alt="course" />
                </a>
                <div style={{ textAlign: "right" }}>
                  <a href={`https://kpsolla.com/courses/${course.id}`}>
                    <small>{course.title} </small>
                  </a>
                  <br />
                  <small>
                    {" "}
                    <i className="far fa-calendar" />{" "}
                    {new Date(course.createdAt).toLocaleString()}
                  </small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> الوصف:</b>
                  <br />
                  <small>{course.descriptions}</small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> سعر الدورة:</b>{" "}
                  <b style={{ color: "green" }}>{course.price}$</b>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> تاريخ الإنشاء: </b>
                  <small>
                    <i className="far fa-calendar" />{" "}
                    {new Date(course.createdAt).toLocaleDateString()}
                  </small>
                </div>
              </div>
              <div className="d-flex">
                <div className="text-right m-2">
                  <b> المتطلبات:</b>
                  <br />
                  <small>{course.prerequisites}</small>
                </div>
              </div>

              <div className="d-flex">
                <div className="text-right m-2">
                  <b> ماذا سيتعلم الطالب:</b>
                  <br />
                  {course.willBeLearnt}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      );
    });
  } else {
    return <div className="">not found</div>;
  }
}

const mapStateToProps = (state: any) => {
  return {
    home: state.AllHome,
  };
};

export default connect(mapStateToProps, {
  getAllStudents,
  getAllInstitutes,
  getAllCourses,
})(StatisticsTable);
