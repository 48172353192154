import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { Loading } from "../../components/loading";
import { Table } from "../../components/shared/Table";
import { configs } from "../../configs";
import { usersActions } from "../../redux/users.duck";

const Customers = (props: any) => {
  const columns = [
    {
      dataField: "username",
      text: <Trans>username</Trans>,
      sort: true,
      formatter: (_, row) => {
        return (
          <div className="flex">
            {row.verified ? (
              <i className="fas text-blue-400 fa-badge-check"></i>
            ) : (
              <i className="fas text-gray-300 fa-badge-check"></i>
            )}
            <div className="px-2">{row.username}</div>
          </div>
        );
      },
    },
    {
      dataField: "email",
      text: <Trans>email</Trans>,
      sort: true,
    },
    {
      dataField: "phone",
      text: <Trans>phone</Trans>,
      sort: true,
    },
    {
      dataField: "createdAt",
      text: <Trans>registered-at</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {new Date(row.createdAt).toLocaleDateString()} --{" "}
            {new Date(row.createdAt).toLocaleTimeString()}
          </div>
        );
      },
    },
    // {
    //   dataField: "createdAt",
    //   text: <Trans>account-status</Trans>,
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="bg-gray-400 inline p-2 rounded-[5px] text-white">
    //         {row.verified ? (
    //           <Trans>verified</Trans>
    //         ) : (
    //           <Trans>not-verified</Trans>
    //         )}
    //       </div>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    props.getRegisteredUsers();
    if(props?.users){
      localStorage.setItem('students',JSON.stringify(props.users));
    }
    console.log("hello");
  }, []);

  if (props.loading) return <Loading />;
  return (
    <section className="">
      <div className="">
        <div className="">
          <div className="">
            {props.paymentRequests?.length < 1 ? (
              <div className="text-center">
                <Trans>no-content</Trans>
              </div>
            ) : (
              <Table
                withSearch={true}
                data={props.users || []}
                columns={columns}
                orderBy="createdAt"
              ></Table>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  //   console.log(state.Approval);
  return {
    // paymentRequests: state.Approval?.paymentRequests,
    loading: state.Users?.loading,
    users: state.Users?.users,
  };
};

export default connect(mapStateToProps, { ...usersActions })(Customers);
