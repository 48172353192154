import { env as envKey } from "../env";

import dev from "./dev.json";
import prod from "./prod.json";
import local from "./local.json";

const envs = [
  { key: "dev", value: dev },
  { key: "prod", value: prod },
  { key: "local", value: local },
];

let env = envs.find((item) => item.key.match(envKey)).value;

interface configs {
  api: string;
  storage_api: string;
}

export const configs: configs = env;
