import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #0b627b;
  opacity: 0.5;
`;
interface props {
  isLoading: boolean;
}
export function Loading({ isLoading }: props) {
  return (
    <div className="sweet-loading d-flex flex-row justify-content-center align-items-center" style={{ width: "100vw", height: "100vh", transition: "10s" }}>
      <ClipLoader css={override} size={100} color={"#123abc"} loading={isLoading} />
    </div>
  );
}

export function DashboardLoading({ isLoading }: props) {
  return (
    <div className="sweet-loading d-flex flex-row justify-content-center align-items-center" style={{ width: "100%", height: "100%", transition: "10s" }}>
      <ClipLoader css={override} size={100} color={"#123abc"} loading={isLoading} />
    </div>
  );
}
