import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { DisapproveInstituteModal } from "../common/instituteReasonModal";
import { InstituteInstructors } from "./institute-instructors-table";

export const ApprovalTable = (props: {
  // courses: Array<any>;
  // approvalDone: any;
  institute: any;
  instituteApproval?: any;
  // getUnapprovedInstitutes: any;
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const filter_by = urlParams.get("filter_by");

  const [show, setShow] = useState(false);
  const [institute, setInstitute]: any = useState(props.institute);

  const [instituteID, setInstituteID] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // console.log(institute);

  // let approved = false;

  // if (filter_by === "approved") {
  //   approved = true;
  // }

  useEffect(() => {
    setInstitute(props.institute);
    //   // if (props?.institute) {
    //   setInstitute(props?.institute);
    //   // console.log(props?.institutes?.institutes);
    //   // }
    //   // if(props?.approvalDone){
    //   //   props.getUnapprovedInstitutes();
    //   //   }
  }, [props]);

  return (
    <div>
      <div className="course-tables">
        <div className="float-right">
          <h3>الشركاء التعليميين - طلبات الموافقة </h3>
          <br></br>
        </div>
        <Table className="the-table" responsive bordered hover>
          <thead>
            <tr>
              {/* <th>#</th> */}
              <th>
                {" "}
                <i className="far fa-info" /> اسم المعهد
              </th>
              <th>
                {" "}
                <i className="far fa-info" /> البريد الالكتروني
              </th>
              <th>
                {" "}
                <i className="far fa-info" /> المدينة
              </th>
              <th>
                {" "}
                <i className="far fa-info" /> تاريخ الانشاء
              </th>
              <th>
                <i className="far fa-user" /> عدد المحاضرين
              </th>
              {/* <th>
                  <i className="far fa-pen" /> تعديل
                </th> */}
              <th>
                <i className="far fa-gear" /> اعدادات
              </th>
            </tr>
          </thead>
          {
            institute?.map((institute: any, idx: number) => {
              // console.log(institute);

              // if (institute.approved !== approved) {
              //   return false;
              // }

              return (
                <tbody key={idx}>
                  <tr>
                    <td className="align-middle">{institute.instituteName}</td>
                    <td className="align-middle">{institute.email}</td>
                    <td className="align-middle">{institute.city}</td>
                    <td className="align-middle">
                      {new Date(institute.createdAt).toLocaleDateString()}
                    </td>

                    <td className="align-middle">{institute.users.length}</td>

                    <td style={{ position: "relative" }}>
                      <div className="d-flex justify-content-center text-center">
                        {institute.approved ? (
                          <div className="m-1">
                            <Button
                              variant="danger"
                              onClick={() => {
                                setInstituteID(institute.id);
                                handleShow();
                              }}
                            >
                              إيقاف
                            </Button>
                          </div>
                        ) : (
                          <div className="m-1">
                            <Button
                              variant="success"
                              onClick={() =>
                                props.instituteApproval(
                                  true,
                                  null,
                                  institute.id
                                )
                              }
                            >
                              موافقة
                            </Button>
                          </div>
                        )}
                      </div>
                      <DisapproveInstituteModal
                        {...props}
                        institute={instituteID}
                        show={show}
                        handleClose={handleClose}
                      />
                    </td>
                  </tr>
                </tbody>
              );
            })

            // }
          }
        </Table>
      </div>
    </div>
  );
};
