import axios from "axios";
import { coursesActions } from "../redux/courses.duck";
import { store } from "../redux/store";

/* ===========================================================
 ======= this proxy is responsable for calling apis its ======
 ======= remote proxy that should handle all of the api ======
 === calls like fetching data, update, save or delete data ===
 =============================================================*/

type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
type Type = "blob" | "json";

/**
 * @param method the request method (GET, POST, PUT, PATCH, DELETE)
 * @param url the request url e.g /api/auth/login
 * @param data the request body if there is a data for post and put routes
 * @param headers the request headers like form data or plain json
 * @returns a promise which will be either pending, fulfilled or rejected
 */

export const APIProxy = (method: Method, url, data, headers, type?: Type) =>
  new Promise(async (resolve, reject) => {
    try {
      // write proxy impelementation e.g (axios, fetch, ajax ...etc)
      const response = await axios.request({
        method,
        url,
        data,
        headers,
        responseType: type,

        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            // console.log(progressEvent.loaded + " " + progressEvent.total);
            coursesActions.opserveUploadProgress(
              (progressEvent.loaded / progressEvent.total) * 100
            )(store.dispatch);
            //  this.updateProgressBarValue(progressEvent);
          }
        },
      });

      // resolve remote proxy response
      resolve(response?.data);
    } catch (errors) {
      // reject remote proxy errors
      reject(errors?.response?.data);
    }
  });
