import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
// import { Loading } from "../loading";
import { connect } from "react-redux";
import { categoriesActions } from "../../redux/reducers/categories.reducer";

import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import { Table } from "../../components/shared/Table";
import { settingsActions } from "../../redux/settings.duck";
const Categories = (props: any) => {
  const [addCatWindow, toggleCatWindow] = useState(false);
  const [item, setItem]: any = useState(null);
  const [countries, setCountries]: any = useState(null);

  const { handleSubmit, setValue, register } = useForm();
  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    setCountries(props.countries);
  }, [props.countries]);

  const addCategory = (data: any) => {
    props.addCountry(data);
    toggleCatWindow(false);
  };

  const columns = [
    {
      dataField: "country",
      text: <Trans>country</Trans>,
      sort: true,
    },
    {
      dataField: "countryCode",
      text: <Trans>Country Code</Trans>,
      sort: true,
    },
    {
      dataField: "currencyName",
      text: <Trans>Currency Name</Trans>,
      sort: true,
    },
    {
      dataField: "flag",
      text: <Trans>flag</Trans>,
      sort: true,
    },
  ];

  return (
    <div>
      <div className="">
        <div className="">
          <div className="d-flex  justify-content-between">
            {/* <div>
              <h3>الدول المتاحة</h3>
            </div> */}
          </div>
          <br />
        </div>

        <Modal
          centered
          onHide={() => toggleCatWindow(false)}
          show={addCatWindow}
        >
          <Modal.Header>
            {item ? <h3>تعديل دولة </h3> : <h3>إضافة دولة</h3>}
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit(addCategory)}>
              <div className="row text-right">
                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor=""> اسم الدولة</label>
                  </div>
                  <input
                    {...register("country", { required: true })}
                    type="text"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12">
                  <div className="mt-2">
                    <label htmlFor="">مفاتاح الدولة</label>
                  </div>
                  <input
                    {...register("countryCode", { required: true })}
                    type="text"
                    className="col-12"
                  />
                </div>
                <div className="input-container col-12 ">
                  <div className="mt-2">
                    <label htmlFor=""> نوع العملة</label>
                  </div>
                  <input
                    {...register("currencyName", { required: true })}
                    type="text"
                    className="col-12"
                  />
                </div>

                <div className="input-container col-12 my-4">
                  <button className="btn btn-primary col-12 text-center">
                    حفظ
                  </button>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </div>
      <div className="">
        <div className="">
          <div className="">
            {props.paymentRequests?.length < 1 ? (
              <div className="text-center">
                <Trans>no-content</Trans>
              </div>
            ) : (
              <Table withSearch={true} data={countries || []} columns={columns}>
                <button
                  onClick={() => toggleCatWindow(true)}
                  type="submit"
                  className="btn btn-primary"
                >
                  إضافة دولة
                </button>
              </Table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  console.log(state.Settings);

  return {
    // categories: state.Categories.categories,
    loading: state.Settings.loading,
    countries: state.Settings.countries,
    // approve: state.Approval.approveWithdrawalRequest,
  };
};

export default connect(mapStateToProps, {
  ...settingsActions,
})(Categories);
