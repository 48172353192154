const SignLayout = ({ children }) => {
  return (
    <div className="sign-wrapper d-flex align-items-center justify-content-center">
      <div className="row m-0 ">
        <div className="m-auto py-3 text-center col-lg-4 px-0 col-md-6 col-sm-8 box">
          {children}
        </div>
      </div>
    </div>
  );
};
export default SignLayout;
