import { useEffect, useState } from "react";

import { connect } from "react-redux";

import { sessionActions } from "../../redux/reducers/session.reducer";
import { systemStatus } from "../../helpers/auth.status";
// import { Table } from "../../components/shared/Table";

import { Trans } from "react-i18next";
import { configs } from "../../configs";
import { useForm } from "react-hook-form";
// import { Navigation } from "../../components/global/Navigation";
import { useLocation } from "react-router";
import { extractQuery } from "../../util/extracter";
import { Link } from "react-router-dom";
// import { paymentActions } from "../../redux/payments.duck";
import { Modal } from "react-bootstrap";

const Profile = (props) => {
  const columns = [
    {
      dataField: "name",
      text: <Trans>account-name</Trans>,
      sort: true,
    },
    {
      dataField: "cardNumber",
      text: <Trans>card-number</Trans>,
      sort: true,
    },
    {
      dataField: "expDate",
      text: <Trans>exp-date</Trans>,
      sort: true,
      formatter: (cell, row) => {
        return (
          <div>
            {new Date(row.expDate).getFullYear()} /{" "}
            {new Date(row.expDate).getMonth()}
          </div>
        );
      },
    },
    {
      dataField: "available",
      text: <Trans>available</Trans>,
      formatter: (cell, row) => {
        return (
          <div>
            {row.available ? (
              <div className="badge btn btn-primary p-2">available</div>
            ) : (
              <div className="badge btn-secondary p-2">not available</div>
            )}
          </div>
        );
      },
    },
    {
      dataField: "defaultCard",
      text: <Trans>default-card</Trans>,
      formatter: (cell, row) => {
        return (
          <div>
            {row.defaultCard ? (
              <div className=" badge badge-info p-2">default card</div>
            ) : (
              <button
                className="btn badge btn-primary p-2"
                onClick={() => props.updateCard(row.id, { defaultCard: true })}
              >
                set default
              </button>
            )}
          </div>
        );
      },
    },
  ];
  // props.getCards()
  const [user, setUser] = useState(null);
  const [addCard, toggleAddCard] = useState(false);
  const updateProfileInfo = useForm();
  const updatePassword = useForm();
  const query: any = extractQuery(useLocation().search);
  useEffect(() => {
    props.getUserInfo();
  }, []);

  useEffect(() => {
    setUser(props.user);
    updateProfileInfo.setValue("firstName", props.user?.firstName);
    updateProfileInfo.setValue("lastName", props.user?.lastName);
  }, [props.user]);
  return (
    <div
      className=" py-3"
      dir={systemStatus.getSystemLang("ar") ? "ltr" : "rtl"}
    >
      <CreateAccount
        show={addCard}
        onCancel={() => toggleAddCard(false)}
        addBillingAccount={props.addBillingAccount}
      />
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 mt-4 m-0 ">
          <div className="col-12 row p-2 mb-2 m-0  text-right">
            <h3>
              <Trans>settings</Trans>
            </h3>
          </div>
          <div className="col-12 row p-2 mb-4 m-0  text-right">
            <div className="col-lg-3  col-md-12 col-sm-12 p-0  m-0 ">
              <div className="row  m-0 p-2  ">
                <Link
                  to="/settings?t=profile"
                  // className={
                  //   query.t === "profile" ? "text-primary" : "text-secondary"
                  // }
                >
                  <i className="fa fa-user"></i> <Trans>profile</Trans>
                </Link>
              </div>
              <div className="row m-0 p-2 py-3">
                <Link
                  to="/settings?t=privacy-security"
                  // className={
                  //   query.t === "privacy-security"
                  //     ? "text-primary"
                  //     : "text-secondary"
                  // }
                >
                  <i className="fa fa-key"></i> <Trans>privacy-security</Trans>
                </Link>
              </div>
            </div>{" "}
            <div className="col-lg-9  col-md-12 col-sm-12 p-0  m-0">
              <div className="row  m-0 p-2">
                {query.t?.match("profile") || !query.t ? (
                  <div className="col-12 p-0  ">
                    <div className="col-12 p-0 pb-3  row m-0">
                      <h5>
                        <Trans>profile</Trans>
                      </h5>
                    </div>
                    <div className="col-lg-12 p-0 row  m-0">
                      <div className="col-lg-2 col-sm-12  p-0">
                        <div className="settings-image">
                          <div className="">
                            <input
                              type="file"
                              style={{ display: "none" }}
                              id="update-image"
                              accept="image/*"
                              name="update-image"
                              onChange={({ target }) => {
                                const form = new FormData();
                                form.append("profileImage", target.files[0]);

                                props.UpdateUserImage(form);
                              }}
                            />
                            <label
                              className="update-image-wrapper"
                              htmlFor="update-image"
                            >
                              <small>
                                <Trans>update-image</Trans>
                              </small>
                            </label>
                          </div>
                          <img
                            className="settings-image"
                            src={`${configs.storage_api}/${user?.profileImage}`}
                            alt="profile"
                          />
                        </div>
                      </div>

                      <div className="col-10 row m-0 ">
                        <form
                          onSubmit={updateProfileInfo.handleSubmit((data) => {
                            props.UpdateUserInfo(data);
                          })}
                          className="col-10"
                        >
                          <div className="mb-3 ">
                            <small className="text-muted row m-0">
                              <Trans>first-name</Trans>
                            </small>
                            <input
                              className="col-12 mt-2 form-control"
                              type="text"
                              placeholder=""
                              {...updateProfileInfo.register("firstName")}
                            />
                          </div>
                          <div className="my-3">
                            <small className="text-muted row m-0">
                              <Trans>last-name</Trans>
                            </small>
                            <input
                              className="col-12 mt-2 form-control"
                              type="text"
                              placeholder=""
                              {...updateProfileInfo.register("lastName")}
                            />
                          </div>

                          <div className="my-3 row m-0">
                            <button className="btn btn-primary">
                              <Trans>save</Trans>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {query.t?.match("privacy-security") ? (
                  <div className="col-12 p-0 ">
                    <div className="col-12  pb-3 p-0 row m-0 ">
                      <h5>
                        <Trans>privacy-security</Trans>
                      </h5>
                    </div>{" "}
                    <div className="col-12  py-2 p-0 row m-0">
                      {/* <small className="text-muted col-12 m-0 p-0">
                        <Trans>reset-password</Trans>
                      </small> */}
                      <form
                        onSubmit={updatePassword.handleSubmit((data) => {
                          props.UpdateUserPassword(data);
                        })}
                        className="col-8 m-0 p-0 row m-0 my-3 "
                      >
                        <div className="my-3 col-12 p-0 row m-0 ">
                          <small className="text-muted ">
                            <Trans>current-password</Trans>
                          </small>
                          <input
                            className="col-12 mt-2 form-control"
                            type="password"
                            placeholder=""
                            {...updatePassword.register("password")}
                          />
                        </div>
                        <div className="row col-12 p-0 m-0">
                          <div className="col-6 m-0 p-0 row m-0">
                            <small className="text-muted">
                              <Trans>new-password</Trans>
                            </small>
                            <input
                              className="col-12 mt-2 form-control"
                              type="password"
                              placeholder=""
                              {...updatePassword.register("newPassword")}
                            />
                          </div>
                          <div className="col-6 m-0 p-0 row m-0">
                            <small className="text-muted">
                              <Trans>confirm-password</Trans>
                            </small>
                            <input
                              className="col-12 mt-2 form-control"
                              type="password"
                              placeholder=""
                              {...updatePassword.register("confirmNewPassword")}
                            />
                          </div>
                        </div>

                        <div className="my-3">
                          <button className="btn btn-primary">
                            <Trans>save</Trans>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state?.Session?.userInfo,
  };
}

export default connect(mapStateToProps, { ...sessionActions })(Profile);

const CreateAccount = (props) => {
  const accountForm = useForm();

  const onSubmit = (data) => {
    const expDate = `${data.year}/${data.month}/${1}`;
    delete data.year;
    delete data.month;
    props.addBillingAccount({ expDate, ...data });
    props.onCancel();
  };
  return (
    <Modal show={props.show} onHide={props.onCancel} size="lg" centered>
      <div className="row m-0  p-2">
        <form
          onSubmit={accountForm.handleSubmit(onSubmit)}
          className="col-12   m-0 p-1 row m-0  "
        >
          <div className="col-12 m-0 p-0">
            <h4>
              <Trans>add-billing-account</Trans>
            </h4>
          </div>
          <div className="my-3 col-6 p-0 row m-0">
            <small className="text-muted ">
              <Trans>account-name</Trans>
            </small>
            <input
              className="col-12 mt-2 form-control"
              type="text"
              placeholder=""
              {...accountForm.register("name", { required: true })}
            />
          </div>
          <div className="my-3 col-6 p-0 row m-0">
            <small className="text-muted ">
              <Trans>card-number</Trans>
            </small>
            <input
              className="col-12 mt-2 form-control"
              type="text"
              placeholder=""
              {...accountForm.register("cardNumber", { required: true })}
            />
          </div>
          <div className="my-3 col-12 p-0 row m-0">
            <small className="text-muted col-12 p-0">
              <Trans>exp-date</Trans>
            </small>
            <div className="row col-5">
              {/* <input
                className="col-2 mt-2 form-control text-center"
                type="text"
                placeholder="dd"
                {...accountForm.register("day", { required: true })}
              />{" "}
              <div className="col-1 d-flex align-items-center">/</div> */}
              <input
                className="col-2 mt-2 form-control text-center"
                type="text"
                placeholder="mm"
                {...accountForm.register("month", { required: true })}
              />
              <div className="col-1 d-flex align-items-center justify-content-center">
                /
              </div>
              <input
                className="col-2 mt-2 form-control text-center"
                type="text"
                placeholder="yyyy"
                {...accountForm.register("year", { required: true })}
              />
            </div>
          </div>
          <div className="my-3">
            <button className="btn btn-primary">
              <Trans>save</Trans>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
