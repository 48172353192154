import { Link, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
// import arrowDown from "../../assets/svgs/chevron-down.svg";

import { ReactComponent as HomeSvg } from "../../assets/svgs/home.svg";
import { ReactComponent as CardSvg } from "../../assets/svgs/card.svg";
import { ReactComponent as SettingsSvg } from "../../assets/svgs/setting.svg";
import { ReactComponent as LocationSvg } from "../../assets/svgs/location.svg";

const Sidebar = () => {
  const url = useLocation().pathname.substring(1);

  return (
    // sidebar outter wrapper
    <div
      id="sidebar-wrapper"
      className="hidden sm:block transition-all   h-screen bg-gray-100 py-2 px-2"
    >
      {/* sidebar inner wrapper */}
      <div className=" px-2 py-2 h-full flex flex-column justify-center">
        {/* sidebar item */}
        <SidebarItem Svg={HomeSvg} title="home" link="home" url={url} />

        <SidebarItem
          Svg={() => <i className="fal fa-users "></i>}
          title="students"
          link="students"
          url={url}
        />
        <SidebarItem
          Svg={() => <i className="fal fa-list-alt "></i>}
          title="categories"
          link="categories"
          url={url}
        />
        <SidebarItem
          Svg={() => <i className="fal fa-percent "></i>}
          title="discounts"
          link="discounts"
          url={url}
        />
        <SidebarItem
          Svg={() => <i className="fal fa-building "></i>}
          title="partners"
          link="partners"
          url={url}
        />
        <SidebarItem Svg={CardSvg} title="payments" link="payments" url={url} />
        <SidebarItem
          Svg={LocationSvg}
          title="countries"
          link="countries"
          url={url}
        />
        <SidebarItem
          Svg={SettingsSvg}
          title="settings"
          link="settings"
          url={url}
        />
      </div>
    </div>
  );
};

const SidebarItem = ({ Svg, title, link, url }) => {
  return (
    <Link
      to={link}
      className={`w-[100px] rounded-full py-2 overflow-hidden cursor-pointer my-2 hover:bg-gray-200 flex flex-column items-center  ${
        url === title && "bg-gray-200"
      }`}
    >
      <div className="">
        <Svg />
      </div>
      {/* item title */}
      <div className="text-[12px] text-gray-500">
        <span>
          <Trans>{title}</Trans>
        </span>
      </div>
    </Link>
  );
};

export default Sidebar;
