import React from "react";
import { store, persister } from "../redux/store";
import { Provider } from "react-redux";
import { SystemRouter } from "../routes";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import "./app.scss";
import { ReactNotifications } from "react-notifications-component";
// higher order component
export const App = () => {
  return (
    <Provider store={store}>
      <ReactNotifications />
      <PersistGate loading={null} persistor={persister}>
        <SystemRouter />
      </PersistGate>
    </Provider>
  );
};
