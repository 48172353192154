import { useEffect, useState } from "react";

import SignLayout from "./layout/SignLayout";
import { sessionActions } from "../../redux/reducers/session.reducer";

import { connect } from "react-redux";
import Logo from "../../components/common/navbar/logo";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
// login component
const Signup = (props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const { handleSubmit, register } = useForm();

  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setErrors(props.session.errors);
    console.log(props.session);
  }, [props.session.errors]);
  useEffect(() => {
    setLoading(props.session.loading);
  }, [props.session.loading]);
  // USING "Formik" form Validation

  const onLogin = (data) => {
    // console.log(data);

    props.login({
      email: data.email?.trim()?.toLowerCase(),
      password: data.password,
    });
  };

  const rememberMeChange = (e) => {
    setRememberMe(e.target.checked);
    // props.remember(e.target.checked);
  };

  return (
    <SignLayout>
      <div className="sign-child">
        <div>
          {" "}
          <Logo width={"30%"} />
        </div>

        <form
          onSubmit={handleSubmit(onLogin)}
          className="text-righted input-holder sign-form login-wrapper"
        >
          {errors && (
            <div className="d-flex flex-row  badge bg-danger p-2 mb-1 align-items-center">
              <span className="text-white  ">{errors}</span>
            </div>
          )}
          <div className="input">
            <label htmlFor="">
              <i className="far fa-user"></i> <Trans>email</Trans>
            </label>
            <input
              type="text"
              {...register("email", { required: true })}
              placeholder=""
            />
          </div>
          <div className="input">
            <label htmlFor="">
              <i className="far fa-lock"></i> <Trans>password</Trans>
            </label>
            <div className="position-relative">
              <input
                type={showPassword ? "text" : "password"}
                {...register("password", { required: true })}
                placeholder=""
              />
              <i
                className={`far ${
                  showPassword ? "fa-eye" : "fa-eye-slash"
                } position-absolute text-secondary btn showpass`}
                id="passToggleEye"
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            </div>
          </div>

          <button type="submit" className="text-center btn-primary">
            {loading ? (
              <div className="lds-ellipsis p-0 m-0">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <Trans>login</Trans>
            )}
          </button>
        </form>
      </div>
    </SignLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.Session,
  };
};

export default connect(mapStateToProps, {
  ...sessionActions,
})(Signup);
